import { fromJS } from 'immutable';

import {
  DOWNLOAD_PAYMENTS,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_ERROR,
  LOAD_CAMPAIGN_SUCCESS,
  FETCH_QR_CODE,
  FETCH_QR_CODE_ERROR,
  FETCH_QR_CODE_SUCCESS,
  RESET_QR_CODE,
  LOAD_RECEIPT_METRICS,
  LOAD_RECEIPT_METRICS_ERROR,
  LOAD_RECEIPT_METRICS_SUCCESS,
} from './constants';

const initialState = fromJS({
  campaign: {},
  campaignIsLoading: false,
  campaignErrorMessage: null,
  fetchingQRCode: false,
  qrCode: '',
  fetchQRCodeError: false,
  receiptMetricsFetching: false,
  receiptMetricsError: false,
  receiptMetrics: null,
});

export default function cashbackCampaignDetailReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_CAMPAIGN:
      return state
        .set('campaignIsLoading', true)
        .set('campaignErrorMessage', null)
        .set('campaign', fromJS({}))
        .set('receiptMetrics', null);
    case LOAD_CAMPAIGN_SUCCESS:
      return state
        .set('campaignIsLoading', false)
        .set('campaignErrorMessage', null)
        .set('campaign', fromJS(action.payload.campaign))
        .set('receiptMetrics', null);
    case LOAD_CAMPAIGN_ERROR:
      return state
        .set('campaignIsLoading', false)
        .set('campaignErrorMessage', action.payload.error)
        .set('campaign', fromJS({}))
        .set('receiptMetrics', null);
    case FETCH_QR_CODE:
      return state.set('fetchingQRCode', true);
    case FETCH_QR_CODE_SUCCESS:
      return state
        .set('fetchingQRCode', false)
        .set('qrCode', action.payload.data);
    case FETCH_QR_CODE_ERROR:
      return state.set('fetchingQRCode', false).set('fetchQRCodeError', true);
    case RESET_QR_CODE:
      return state.set('qrCode', '');
    case LOAD_RECEIPT_METRICS:
      return state
        .set('receiptMetricsFetching', true)
        .set('receiptMetricsError', false)
        .set('receiptMetrics', null);
    case LOAD_RECEIPT_METRICS_SUCCESS:
      return state
        .set('receiptMetricsFetching', false)
        .set('receiptMetricsError', false)
        .set('receiptMetrics', {
          data: action.payload.data,
        });
    case LOAD_RECEIPT_METRICS_ERROR:
      return state
        .set('receiptMetricsFetching', false)
        .set('receiptMetricsError', true)
        .set('receiptMetrics', null);
    case DOWNLOAD_PAYMENTS:
    default:
      return state;
  }
}

export { initialState };
