import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectOnboardingFlow,
  selectProductType,
} from './containers/OnboardingKYCContainer/selectors';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from './ga4';

export function usePrevious(value: never) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useOnboardingPageView(pageName?: string, action = 'pageview') {
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const productType = useSelector(selectProductType);

  useEffect(() => {
    // check for present values to prevent null value on initial render
    if (onboardingFlow && productType) {
      analyticsEvent(
        pageName ?? action,
        AnalyticCategories.ONBOARDING,
        'onboarding-event',
        {
          flow: onboardingFlow,
          product: analyticsProductTypeMapper(productType),
        },
      );
    }
  }, [onboardingFlow, productType]);
}
