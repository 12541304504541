import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  blokkieTheme,
  BlokkieIconButton,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useSelector } from 'react-redux';

import { Shade } from '../styledComponents';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';

interface InfoProps {
  title?: string;
  show?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  fromPage: string;
}

export function InfoScreen({
  title,
  show,
  onClick,
  children,
  fromPage,
}: InfoProps): JSX.Element {
  // eslint-disable-next-line no-use-before-define
  const classes = useStyles();
  const [slidedDown, setSlidedDown] = useState(true);
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);

  useEffect(
    () => {
      const animatedInfo = document.getElementById('info');
      animatedInfo?.addEventListener('animationend', (event) => {
        if (event.animationName.includes('slideDown')) {
          setSlidedDown(true);
        }
      });

      animatedInfo?.addEventListener('animationstart', (event) => {
        if (event.animationName.includes('slideUp')) {
          setSlidedDown(false);
        }
      });

      analyticsEvent(
        'info_click',
        AnalyticCategories.ONBOARDING,
        'onboarding-event',
        {
          on_page: fromPage,
          flow: onboardingFlow,
          product: analyticsProductTypeMapper(productType),
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const infoscreenTitle = title || '';

  const getAnimationClasses = (): string => {
    if (show) {
      return `${classes.infoSlideUp} ${classes.slideUpAnimation}`;
    }

    if (slidedDown) {
      return `${classes.infoSlideUp}`;
    }

    return `${classes.infoSlideUp} ${classes.slideDownAnimation}`;
  };

  return (
    <div id="info" className={getAnimationClasses()}>
      <div className={classes.infoHeader}>
        <div>
          <BlokkieTypography variant="h3">{infoscreenTitle}</BlokkieTypography>
        </div>
        <div className={classes.infoClose}>
          <BlokkieIconButton variant="cross" onClick={onClick} />
        </div>
      </div>
      <div className={classes.infoBody}>{children}</div>
      <Shade minHeight={60} marginTop={-60} />
    </div>
  );
}

const useStyles = createUseStyles({
  infoHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: blokkieTheme.spacing(4),
    paddingTop: blokkieTheme.spacing(3),
    borderBottom: `1px solid ${blokkieTheme.colors.grey.light}`,
  },
  infoBody: {
    padding: `${blokkieTheme.spacing(3)}px ${blokkieTheme.spacing(
      4,
    )}px ${blokkieTheme.spacing(4)}px ${blokkieTheme.spacing(4)}px`,
    height: `calc(100% - ${blokkieTheme.spacing(11)}px)`,
    overflowY: 'auto',
  },
  infoClose: {
    marginRight: -blokkieTheme.spacing(2),
    outline: 'none',
    userSelect: 'none',
  },
  infoSlideUp: {
    background: blokkieTheme.colors.grey.white,
    zIndex: '999',
    display: 'none',
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    bottom: 0,
    right: 0,
    color: blokkieTheme.colors.grey.veryDark,
  },
  slideUpAnimation: {
    display: 'block',
    animation: '$slideUp 0.6s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  '@keyframes slideUp': {
    '0%': {
      top: '100%',
      display: 'block',
    },
    '100%': {
      top: '0px',
      display: 'block',
    },
  },
  slideDownAnimation: {
    animation: '$slideDown 0.6s, $hide 0s 0.6s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  '@keyframes slideDown': {
    '0%': {
      top: '0px',
      display: 'block',
    },
    '100%': {
      top: '100%',
      display: 'block',
    },
  },
  '@keyframes hide': {
    '0%': {
      display: 'none',
    },
    '100%': {
      display: 'none',
    },
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    infoBody: {
      padding: blokkieTheme.spacing(3),
      backgroundColor: 'white',
    },
    infoHeader: {
      padding: blokkieTheme.spacing(3),
      paddingTop: blokkieTheme.spacing(2),
    },
    infoSlideUp: {
      position: 'fixed',
    },
  },
});
