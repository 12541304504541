export const LOAD_CAMPAIGN = 'app/CampaignDetailContainer/LOAD_CAMPAIGN';
export const LOAD_CAMPAIGN_SUCCESS =
  'app/CampaignDetailContainer/LOAD_CAMPAIGN_SUCCESS';
export const LOAD_CAMPAIGN_ERROR =
  'app/CampaignDetailContainer/LOAD_CAMPAIGN_ERROR';
export const DOWNLOAD_PAYMENTS =
  'app/CampaignDetailContainer/DOWNLOAD_PAYMENTS';
export const FETCH_QR_CODE = 'app/CampaignDetailContainer/FETCH_QR_CODE';
export const FETCH_QR_CODE_SUCCESS =
  'app/CampaignDetailContainer/FETCH_QR_CODE_SUCCESS';
export const FETCH_QR_CODE_ERROR =
  'app/CampaignDetailContainer/FETCH_QR_CODE_ERROR';
export const RESET_QR_CODE = 'app/CampaignDetailContainer/RESET_QR_CODE';
export const LOAD_RECEIPT_METRICS =
  'app/CampaignDetailContainer/LOAD_RECEIPT_METRICS';
export const LOAD_RECEIPT_METRICS_SUCCESS =
  'app/CampaignDetailContainer/LOAD_RECEIPT_METRICS_SUCCESS';
export const LOAD_RECEIPT_METRICS_ERROR =
  'app/CampaignDetailContainer/LOAD_RECEIPT_METRICS_ERROR';
