/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '!file-loader?name=[name].[ext]!../../../images/videoGifs/thumbs-up.mp4';

import { CenterWrapper, OnboardingParagraph } from '../styledComponents';
import { Space } from '../../styleguide';
import TikkieTerms from '../Agreement/TikkieTerms';

import Loader from '../Loader/index';
import { TERMS_URL } from '../Agreement/config';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import ButtonFooter from '../components/ButtonFooter';
import {
  tikkieBusiness,
  tikkieRecycling,
} from '../../../containers/OnboardingKYCContainer/config';
import CostRectangles from '../Agreement/CostRectangles';
import { selectProductType } from '../../../containers/OnboardingKYCContainer/selectors';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface TermsProps {
  terms: string;
  isGettingTerms: boolean;
  getTerms(): void;
  acceptTerms(): void;
}

function Terms({
  terms,
  isGettingTerms,
  getTerms,
  acceptTerms,
}: TermsProps): JSX.Element {
  const { t } = useTranslation();
  const productType = useSelector(selectProductType);
  const [values, setValues] = useState({
    showCosts: false,
    showTerms: false,
  });
  useOnboardingPageView('terms');

  useEffect(() => {
    getTerms();
  }, []);

  const toggleShowCosts = (): void =>
    setValues((prevState) => ({
      ...prevState,
      showCosts: !prevState.showCosts,
      showTerms: false,
    }));

  const toggleShowTerms = (): void =>
    setValues((prevState) => ({
      ...prevState,
      showCosts: false,
      showTerms: !prevState.showTerms,
    }));

  if (isGettingTerms) {
    return <Loader />;
  }

  return (
    <>
      <CenterWrapper>
        <BlokkieGifContainer
          source="/thumbs-up.mp4"
          style={{ marginTop: blokkieTheme.spacing(3) }}
        />
        <Title>{t('onboarding.terms.title')}</Title>
        <OnboardingParagraph textAlign="center" marginBottom={Space.s32}>
          <BlokkieTypography variant="pl500">
            {t('onboarding.terms.body1')}
          </BlokkieTypography>{' '}
          <BlokkieTextLink
            variant="pl700"
            underline
            onClick={() => {
              if ([tikkieBusiness, tikkieRecycling].includes(productType)) {
                toggleShowCosts();
              }
            }}
            color={blokkieTheme.colors.grey.veryDark}
            data-test-id="terms-open-costs-info-screen"
          >
            {t('onboarding.terms.body2')}
          </BlokkieTextLink>{' '}
          <BlokkieTextLink
            variant="pl700"
            underline
            onClick={toggleShowTerms}
            color={blokkieTheme.colors.grey.veryDark}
            data-test-id="terms-open-terms-info-screen"
          >
            {t('onboarding.terms.body3')}
          </BlokkieTextLink>{' '}
          <BlokkieTypography variant="pl500">
            {t('onboarding.terms.body4')}
          </BlokkieTypography>{' '}
          <BlokkieTextLink
            href={TERMS_URL}
            target="_blank"
            variant="pl700"
            underline
            color={blokkieTheme.colors.grey.veryDark}
          >
            {t('onboarding.terms.body5')}
          </BlokkieTextLink>
          <BlokkieTypography variant="pl500">?</BlokkieTypography>
        </OnboardingParagraph>
        <ButtonFooter>
          <BlokkieButton
            onClick={acceptTerms}
            data-test-id="Onboarding-Terms-GoNextButton"
            variant="primary"
            size="large"
            style={{ width: 268 }}
          >
            {t('onboarding.terms.button')}
          </BlokkieButton>
        </ButtonFooter>
      </CenterWrapper>

      <CostRectangles
        show={values.showCosts}
        toggleShowCosts={toggleShowCosts}
      />

      <TikkieTerms
        show={values.showTerms}
        toggleShowTerms={() => toggleShowTerms()}
        terms={terms}
      />
    </>
  );
}

export default Terms;
