import '!file-loader?name=[name].[ext]!../../../images/videoGifs/shocked-monkey.mp4';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/sad.mp4';
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import ButtonFooter from '../components/ButtonFooter';
import ButtonHeader from '../components/ButtonHeader';
import { CenterWrapper } from '../styledComponents';
import NavigationHeader from '../components/NavigationHeader';

interface ScreenProps {
  previousStep?: () => void;
  showGif?: boolean;
  sadGifVersion?: boolean;
  header: string;
  buttonClick?: () => void;
  buttonText?: string;
  children?: React.ReactNode;
  goToLoginScreen?: () => void;
}

function Screen({
  previousStep,
  showGif,
  sadGifVersion,
  header,
  buttonClick,
  buttonText,
  children,
  goToLoginScreen,
}: ScreenProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <NavigationHeader clickLeft={(previousStep as () => void) ?? undefined} />
      {goToLoginScreen && (
        <ButtonHeader align="right">
          <BlokkieTextLink
            onClick={goToLoginScreen}
            variant="pl700"
            style={{
              color: blokkieTheme.colors.primary.green,
              textDecoration: 'none',
            }}
          >
            {t('login')}
          </BlokkieTextLink>
        </ButtonHeader>
      )}
      <CenterWrapper>
        {showGif && (
          <BlokkieGifContainer
            source={sadGifVersion ? '/sad.mp4' : '/shocked-monkey.mp4'}
            style={{ margin: `${blokkieTheme.spacing(2)}px 0` }}
          />
        )}
        <BlokkieTypography
          variant="h2"
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            marginBottom: blokkieTheme.spacing(1),
            textAlign: 'center',
          }}
          data-test-id="Onboarding-Rejection-Header"
        >
          {header}
        </BlokkieTypography>
        {children}
        {buttonClick && buttonText && (
          <ButtonFooter>
            <BlokkieButton
              data-test-id="error-screen-button"
              variant="primary"
              size="large"
              onClick={buttonClick}
            >
              {buttonText}
            </BlokkieButton>
          </ButtonFooter>
        )}
      </CenterWrapper>
    </>
  );
}
export default Screen;
