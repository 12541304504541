import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import reducer from './reducer';
import saga from './saga';
import injectSaga from '../../../utils/injectSaga';
import injectReducer from '../../../utils/injectReducer';

import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';

import {
  selectCampaign,
  selectCampaignErrorMessage,
  selectCampaignIsLoading,
  selectFetchingQRCode,
  selectFetchQRCodeError,
  selectQRCode,
  selectReceiptMetrics,
  selectReceiptMetricsFetching,
  selectReceiptMetricsError,
} from './selectors';

import { selectUser } from '../../App/selectors';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import CampaignDetail from '../../../components/Cashback/CampaignDetail';
import {
  loadCampaign,
  downloadPayments,
  resetQRCode,
  loadReceiptMetrics,
} from './actions';

function CashbackCampaignDetailContainer(props) {
  const history = useHistory();
  const match = useRouteMatch();

  const showCampaigns = () => {
    history.push('/tikkieterug');
  };

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }
  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <CampaignDetail
            campaign={props.campaign}
            campaignIsLoading={props.campaignIsLoading}
            campaignErrorMessage={props.campaignErrorMessage}
            loadCampaign={props.loadCampaign}
            showCampaigns={showCampaigns}
            downloadPayments={props.downloadPayments}
            match={match}
            fetchingQRCode={props.fetchingQRCode}
            fetchQRCodeError={props.fetchQRCodeError}
            qrCode={props.qrCode}
            resetQRCode={props.resetQRCode}
            loadReceiptMetrics={props.loadReceiptMetrics}
            receiptMetrics={props.receiptMetrics}
            receiptMetricsFetching={props.receiptMetricsFetching}
            receiptMetricsError={props.receiptMetricsError}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

CashbackCampaignDetailContainer.propTypes = {
  user: PropTypes.object,
  campaign: PropTypes.object,
  campaignIsLoading: PropTypes.bool,
  campaignErrorMessage: PropTypes.string,
  loadCampaign: PropTypes.func,
  downloadPayments: PropTypes.func,
  fetchingQRCode: PropTypes.bool,
  fetchQRCodeError: PropTypes.bool,
  qrCode: PropTypes.string,
  resetQRCode: PropTypes.func,
  loadReceiptMetrics: PropTypes.func,
  receiptMetrics: PropTypes.object,
  receiptMetricsFetching: PropTypes.bool,
  receiptMetricsError: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  campaign: selectCampaign,
  campaignIsLoading: selectCampaignIsLoading,
  campaignErrorMessage: selectCampaignErrorMessage,
  fetchingQRCode: selectFetchingQRCode,
  fetchQRCodeError: selectFetchQRCodeError,
  qrCode: selectQRCode,
  receiptMetrics: selectReceiptMetrics,
  receiptMetricsFetching: selectReceiptMetricsFetching,
  receiptMetricsError: selectReceiptMetricsError,
});

const mapDispatchToProps = (dispatch) => ({
  loadCampaign: (campaignId) => dispatch(loadCampaign(campaignId)),
  downloadPayments: (campaignId) => dispatch(downloadPayments(campaignId)),
  resetQRCode: () => dispatch(resetQRCode()),
  loadReceiptMetrics: (campaign) => dispatch(loadReceiptMetrics({ campaign })),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'campaign/detailContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'campaign/detailContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(CashbackCampaignDetailContainer);
