import {
  DOWNLOAD_PAYMENTS,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_ERROR,
  LOAD_CAMPAIGN_SUCCESS,
  FETCH_QR_CODE_SUCCESS,
  FETCH_QR_CODE_ERROR,
  RESET_QR_CODE,
  LOAD_RECEIPT_METRICS,
  LOAD_RECEIPT_METRICS_SUCCESS,
  LOAD_RECEIPT_METRICS_ERROR,
} from './constants';

export function loadCampaign(campaignId) {
  return {
    type: LOAD_CAMPAIGN,
    payload: {
      campaignId,
    },
  };
}

export function loadCampaignSuccess(campaign) {
  return {
    type: LOAD_CAMPAIGN_SUCCESS,
    payload: {
      campaign,
    },
  };
}

export function loadCampaignError(error) {
  return {
    type: LOAD_CAMPAIGN_ERROR,
    payload: {
      error,
    },
  };
}

export function downloadPayments(campaignId) {
  return {
    type: DOWNLOAD_PAYMENTS,
    payload: {
      campaignId,
    },
  };
}

export function fetchQRCodeSuccess(response) {
  return {
    type: FETCH_QR_CODE_SUCCESS,
    payload: {
      data: response,
    },
  };
}

export function fetchQRCodeError(error) {
  return {
    type: FETCH_QR_CODE_ERROR,
    payload: {
      error,
    },
  };
}

export function resetQRCode() {
  return {
    type: RESET_QR_CODE,
  };
}

export function loadReceiptMetrics(request) {
  return {
    type: LOAD_RECEIPT_METRICS,
    payload: {
      request,
    },
  };
}

export function loadReceiptMetricsSuccess(data) {
  return {
    type: LOAD_RECEIPT_METRICS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function loadReceiptMetricsError(error) {
  return {
    type: LOAD_RECEIPT_METRICS_ERROR,
    payload: {
      error,
    },
  };
}
