import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Terms from '../../../components/OnboardingKYC/Terms';
import FormWrapperContainer from '../FormWrapperContainer';
import { acceptTerms, getTerms } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectIsGettingTerms,
  selectIsSecondApplicant,
  selectProductType,
  selectTerms,
} from '../selectors';
import { productTypeToTermsMap } from '../config';

function TermsContainer(props) {
  const { isSecondApplicant, productType } = props;
  const termsType = productTypeToTermsMap.get(productType);

  return (
    <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
      <Terms
        terms={props.terms}
        isGettingTerms={props.isGettingTerms}
        getTerms={() => props.getTerms(termsType)}
        acceptTerms={() => props.acceptTerms(isSecondApplicant)}
        productType={productType}
      />
    </FormWrapperContainer>
  );
}

TermsContainer.propTypes = {
  getTerms: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  isGettingTerms: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  isSecondApplicant: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  terms: selectTerms,
  isGettingTerms: selectIsGettingTerms,
  isSecondApplicant: selectIsSecondApplicant,
  productType: selectProductType,
});

const mapDispatchToProps = (dispatch) => ({
  getTerms: (type) => dispatch(getTerms(type)),
  acceptTerms: (forSecondApplicant) =>
    dispatch(acceptTerms(forSecondApplicant)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(TermsContainer);
