/**
 *
 * PaymentRequestDetail
 *
 */

import React from 'react';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';

import '!file-loader?name=[name].[ext]!../../../images/gifs/oops.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/no-results.gif';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';

import PaymentRequestFilters from '../PaymentRequestFilters';
import PaymentRequestLineItem from '../PaymentRequestLineItem';

import messages from './messages';
import {
  Color,
  Header1,
  SecondaryButton,
  DownloadButton,
  Tabs,
  TabItem,
} from '../../styleguide';
import { GiphyBox } from '../../components';
import csvTypes from '../../../utils/csvTypes';
import ShortDisturbanceMessage from '../../SDM';

const PaymentRequestListWrapper = styled.div``;

const Header = styled(Header1)`
  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1;

  @media ${(props) => props.theme.device.mobile} {
    padding: 0px 16px 16px 16px;
  }
`;

const FiltersWrapper = styled.div`
  margin-top: 30px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 116px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 276px;
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: ${(props) => (props.slideIn ? '0px' : '-96px')};
  left: 0px;
  right: 0px;
  background-color: ${Color.white};
  box-shadow: 0 -4px 30px 0 rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s;
`;

const FooterContent = styled.div`
  width: 1056px;
  margin: 0 auto;
  padding: 24px 0px 24px 0px;
  line-height: 48px;
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: ${Color.gray01};

  a {
    font-family: MuseoSansRounded700;
    color: ${Color.brandColor01};
  }

  button {
    float: right;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: auto;
    margin: 0;
    padding: 24px 32px 24px 32px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class PaymentRequestList extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const oopsTitle = intl.formatMessage({
      id: 'app.components.PaymentRequestList.oopsTitle',
    });

    const oopsText = intl.formatMessage({
      id: 'app.components.PaymentRequestList.oopsText',
    });

    const oopsButton = intl.formatMessage({
      id: 'app.components.PaymentRequestList.oopsButton',
    });

    const noResultsTitle = intl.formatMessage({
      id: 'app.components.PaymentRequestList.noResultsTitle',
    });

    const noResultsText = intl.formatMessage({
      id: 'app.components.PaymentRequestList.noResultsText',
    });

    const noResultsButton = intl.formatMessage({
      id: 'app.components.PaymentRequestList.noResultsButton',
    });

    const firstUseTitle = intl.formatMessage({
      id: 'app.components.PaymentRequestList.firstUseTitle',
    });

    const firstUseText = intl.formatMessage({
      id: 'app.components.PaymentRequestList.firstUseText',
    });

    const onePaymentEventSelectedText = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.onePaymentEventSelected',
    });

    const paymentEventsSelectedText = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.paymentEventsSelected',
    });

    const allText = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.all',
    });

    this.state = {
      oopsTitle,
      oopsText,
      oopsButton,
      noResultsTitle,
      noResultsText,
      noResultsButton,
      firstUseTitle,
      firstUseText,
      onePaymentEventSelectedText,
      paymentEventsSelectedText,
      allText,
      currentPage: 1,
      allSelected: 'no',
      selectedPaymentRequests: [],
    };
  }

  componentDidUpdate(oldProps) {
    const isResultsModificationDetected =
      (oldProps.isCreating && !this.props.isCreating) ||
      (oldProps.isUploading && !this.props.isUploading);

    if (isResultsModificationDetected) {
      this.setState({ currentPage: 1 });
      if (this.props.paymentRequests.totalElements === 0) {
        this.state.allSelected = 'no';
      }
    }
  }

  onChangePaging = (currentPage) => this.setState({ currentPage });

  showPaging = () => this.props.paymentRequests.totalElements > 20;

  hasResults = () =>
    this.props.paymentRequests && this.props.paymentRequests.totalElements > 0;

  hasFilters = () =>
    this.props.filterDateFrom !== 0 ||
    this.props.filterDateTo !== 0 ||
    // eslint-disable-next-line eqeqeq
    this.props.filterStatus != '' ||
    // eslint-disable-next-line eqeqeq
    this.props.filterSearch != '';

  loadPaymentRequests = (
    currentPage,
    filterDateLabel,
    filterDateFrom,
    filterDateTo,
    filterStatus,
    filterSearch,
  ) => {
    // The filter search criteria was changed. This means that the page counter
    // must be reset to the first page.  Reset the counter and then it will trigger
    // the filter to call this function again to load the results.
    // eslint-disable-next-line eqeqeq
    if (currentPage != this.state.currentPage) {
      this.setState({ currentPage });
    } else {
      this.props.loadPaymentRequests(
        currentPage,
        filterDateLabel,
        filterDateFrom,
        filterDateTo,
        filterStatus,
        filterSearch,
      );
    }
  };

  itemsSelected = () =>
    !!this.props.user.downloadOption &&
    (this.state.allSelected === 'yes' ||
      this.state.selectedPaymentRequests.length > 0);

  isSelected = (token) => {
    const index = this.state.selectedPaymentRequests.indexOf(token);
    const hasNotAllSelected = this.state.allSelected === 'no';
    const isValidIndex = index > -1;
    return hasNotAllSelected === isValidIndex ? 'yes' : 'no';
  };

  itemsSelectedText = () => {
    if (this.state.allSelected === 'no') {
      return this.state.selectedPaymentRequests.length === 1
        ? this.state.onePaymentEventSelectedText
        : this.state.selectedPaymentRequests.length +
            this.state.paymentEventsSelectedText;
    } else if (this.state.allSelected === 'partial') {
      const numberOfItems =
        this.props.paymentRequests.totalElements -
        this.state.selectedPaymentRequests.length;
      return numberOfItems === 1
        ? this.state.onePaymentEventSelectedText
        : numberOfItems + this.state.paymentEventsSelectedText;
    }
    return (
      this.state.allText +
      this.props.paymentRequests.totalElements +
      this.state.paymentEventsSelectedText
    );
  };

  togglePaymentRequestSelection = (token) => {
    this.setState((prevState) => {
      let { allSelected } = prevState;
      let selectedPaymentRequests = prevState.selectedPaymentRequests.slice(0);
      const index = selectedPaymentRequests.indexOf(token);
      if (index > -1) {
        selectedPaymentRequests.splice(index, 1);
        if (allSelected === 'partial' && selectedPaymentRequests.length === 0) {
          allSelected = 'yes';
        }
      } else {
        selectedPaymentRequests.push(token);
        if (allSelected === 'yes') {
          allSelected = 'partial';
        } else if (allSelected === 'partial') {
          if (
            selectedPaymentRequests.length ===
            this.props.paymentRequests.totalElements
          ) {
            selectedPaymentRequests = [];
            allSelected = 'no';
          }
        }
      }

      return {
        selectedPaymentRequests,
        allSelected,
      };
    });
  };

  resetSelection = () => {
    const allSelected = 'no';
    const selectedPaymentRequests = [];
    this.setState({ allSelected, selectedPaymentRequests });
  };

  toggleAllSelected = () => {
    this.setState((prevState) => {
      let { allSelected } = prevState;
      allSelected = allSelected === 'no' ? 'yes' : 'no';
      const selectedPaymentRequests = [];
      return { allSelected, selectedPaymentRequests };
    });
  };

  downloadMultiplePaymentRequests = (csvType) => {
    this.props.downloadMultiplePaymentRequests(
      this.props.filterDateLabel,
      this.props.filterDateFrom,
      this.props.filterDateTo,
      this.props.filterStatus,
      this.props.filterSearch,
      this.state.allSelected,
      this.state.selectedPaymentRequests,
      csvType,
    );
  };

  render() {
    return (
      <PaymentRequestListWrapper>
        <ShortDisturbanceMessage context="business-portal-overview" />
        <Header>
          <FormattedMessage {...messages.header} />
        </Header>
        <Tabs>
          <TabItem to="/overview" active={1}>
            <FormattedMessage {...messages.tabAll} />
          </TabItem>
          {this.props.user.bulkOption && (
            <TabItem id="bulkTikkies" to="/bulk" active={0}>
              <FormattedMessage {...messages.tabBulkUploads} />
            </TabItem>
          )}
          <DownloadButton
            target="_blank"
            href="https://www.tikkie.me/zakelijk/portal-handleiding"
          >
            <FormattedMessage {...messages.downloadManual} />
          </DownloadButton>
        </Tabs>
        <FiltersWrapper>
          <PaymentRequestFilters
            loadPaymentRequests={this.loadPaymentRequests}
            currentPage={this.state.currentPage}
            filterDateFrom={this.props.filterDateFrom}
            filterDateTo={this.props.filterDateTo}
            filterDateLabel={this.props.filterDateLabel}
            filterStatus={this.props.filterStatus}
            filterSearch={this.props.filterSearch}
            showCreateTikkie={this.props.showCreateTikkie}
            showBulkTikkie={this.props.showBulkTikkie}
            downloadMultiplePaymentRequests={
              this.props.downloadMultiplePaymentRequests
            }
            hasResults={this.hasResults()}
            bulkOption={this.props.user.bulkOption}
            allSelected={this.state.allSelected}
            toggleAllSelected={this.toggleAllSelected}
            resetSelection={this.resetSelection}
          />
        </FiltersWrapper>
        {/* eslint-disable-next-line no-prototype-builtins */}
        {this.props.paymentRequests.hasOwnProperty('paymentEvents') && (
          <Content>
            {!!this.props.paymentRequestsErrorMessage && (
              <GifjeContainer>
                <GifjeWrapper>
                  <GiphyBox
                    gif="oops.gif"
                    onClick={() => {
                      this.props.loadPaymentRequests(1, '', 0, 0, [], '');
                    }}
                    title={this.state.oopsTitle}
                    text={this.state.oopsText}
                    button={this.state.oopsButton}
                  />
                </GifjeWrapper>
              </GifjeContainer>
            )}
            {this.props.paymentRequests.paymentEvents.length === 0 &&
              this.hasFilters() && (
                <GifjeContainer>
                  <GifjeWrapper>
                    <GiphyBox
                      gif="no-results.gif"
                      onClick={() => {
                        this.props.loadPaymentRequests(1, '', 0, 0, [], '');
                      }}
                      title={this.state.noResultsTitle}
                      text={this.state.noResultsText}
                      button={this.state.noResultsButton}
                    />
                  </GifjeWrapper>
                </GifjeContainer>
              )}
            {this.props.paymentRequests.paymentEvents.length === 0 &&
              !this.hasFilters() && (
                <GifjeContainer>
                  <GifjeWrapper>
                    <GiphyBox
                      gif="no-results.gif"
                      title={this.state.firstUseTitle}
                      text={this.state.firstUseText}
                    />
                  </GifjeWrapper>
                </GifjeContainer>
              )}
            {this.props.paymentRequests.paymentEvents.map((paymentRequest) => (
              <PaymentRequestLineItem
                key={paymentRequest.id}
                paymentRequest={paymentRequest}
                selected={this.isSelected(paymentRequest.token)}
                togglePaymentRequest={() =>
                  this.togglePaymentRequestSelection(paymentRequest.token)
                }
                showTikkieDetail={() =>
                  this.props.showTikkieDetail(paymentRequest)
                }
              />
            ))}
            {this.showPaging() && (
              <PagingWrapper>
                <Pagination
                  onChange={this.onChangePaging}
                  defaultCurrent={1}
                  current={this.state.currentPage}
                  pageSize={20}
                  simple={isMobileOnly}
                  showLessItems={isTablet}
                  total={this.props.paymentRequests.totalElements}
                  prevIcon={<NavPrev src="back.svg" />}
                  nextIcon={<NavNext src="back.svg" />}
                  showTitle={false}
                />
              </PagingWrapper>
            )}
            <FooterWrapper slideIn={this.itemsSelected()}>
              {this.itemsSelected() && (
                <FooterContent>
                  {this.itemsSelectedText()}
                  <SecondaryButton
                    rounded
                    onClick={() => {
                      this.downloadMultiplePaymentRequests(csvTypes.payment);
                    }}
                  >
                    <img src="downloadicon.svg" alt="" />
                    <FormattedMessage {...messages.downloadPayments} />
                  </SecondaryButton>
                  <SecondaryButton
                    rounded
                    margin="0px 8px 0px 0px"
                    onClick={() => {
                      this.downloadMultiplePaymentRequests(
                        csvTypes.paymentRequest,
                      );
                    }}
                  >
                    <img src="downloadicon.svg" alt="" />
                    <FormattedMessage {...messages.downloadPaymentRequests} />
                  </SecondaryButton>
                </FooterContent>
              )}
            </FooterWrapper>
          </Content>
        )}
      </PaymentRequestListWrapper>
    );
  }
}

PaymentRequestList.propTypes = {
  isCreating: PropTypes.bool,
  isUploading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  paymentRequests: PropTypes.object.isRequired,
  paymentRequestsIsLoading: PropTypes.bool.isRequired,
  paymentRequestsErrorMessage: PropTypes.string,
  loadPaymentRequests: PropTypes.func.isRequired,
  showDashboard: PropTypes.func.isRequired,
  showBulkList: PropTypes.func.isRequired,
  showCreateTikkie: PropTypes.func.isRequired,
  showBulkTikkie: PropTypes.func.isRequired,
  showTikkieDetail: PropTypes.func.isRequired,
  filterDateFrom: PropTypes.number,
  filterDateTo: PropTypes.number,
  filterStatus: PropTypes.array,
  filterSearch: PropTypes.string,
  filterDateLabel: PropTypes.string,
  downloadMultiplePaymentRequests: PropTypes.func.isRequired,
};

export default injectIntl(PaymentRequestList);
