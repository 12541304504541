import { takeLatest, call, put, all } from 'redux-saga/effects';
import moment from 'moment';

import request from '../../../utils/request';
import { findFirstWeekDayOnOrBefore } from '../../../utils';
import config from '../../../config';
import {
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_SUCCESS,
  DOWNLOAD_PAYMENTS,
  FETCH_QR_CODE,
  LOAD_RECEIPT_METRICS,
} from './constants';

import {
  loadCampaignError,
  loadCampaignSuccess,
  fetchQRCodeSuccess,
  fetchQRCodeError,
  loadReceiptMetricsSuccess,
  loadReceiptMetricsError,
} from './actions';

export default function* watcherSaga() {
  yield all([
    takeLatest(LOAD_CAMPAIGN, loadCampaignSaga),
    takeLatest(DOWNLOAD_PAYMENTS, downloadPaymentsSaga),
    takeLatest(FETCH_QR_CODE, fetchQRCodeSaga),
    takeLatest(LOAD_CAMPAIGN_SUCCESS, fetchQRCodeSaga),
    takeLatest(LOAD_RECEIPT_METRICS, loadReceiptMetricsSaga),
  ]);
}

export function* loadCampaignSaga(action) {
  const { campaignId } = action.payload;

  const requestUrl = `${config.API_URL}/ms/cashback/campaigns`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    const result =
      response.length > 0 && response.find((i) => i.id === campaignId);
    if (result) yield put(loadCampaignSuccess(result));
    else yield put(loadCampaignError('Not Found'));
  } catch (err) {
    yield put(loadCampaignError(err.message));
  }
}

export function* downloadPaymentsSaga(action) {
  const { campaignId } = action.payload;
  const url = `${config.API_URL}/ms/cashback/campaign/${campaignId}/payments`;
  window.open(url, '_blank');
}

export function* fetchQRCodeSaga(action) {
  const requestUrl = `${config.QR_SERVICE_URL}/qrcode`;
  const body = {
    url: action.payload.campaign.shareUrl,
    size: 30,
    style: 'round',
  };
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    yield put(fetchQRCodeSuccess(response.qrCodeImage));
  } catch (err) {
    yield put(fetchQRCodeError(err.message));
  }
}

export function* loadReceiptMetricsSaga(action) {
  const campaignId = action.payload.request.campaign.id;
  const today = new Date().toISOString().substring(0, 10);
  const endDateScans =
    today <= action.payload.request.campaign.endDate
      ? today
      : action.payload.request.campaign.endDate;
  // start at a monday:
  const dateFrom = findFirstWeekDayOnOrBefore(
    action.payload.request.campaign.startDate,
    1,
  );
  // have charts with 14 days on them:
  const dateTo = selectEndDate(dateFrom, endDateScans);
  const requestUrl = `${config.API_URL}/ms/cashback-query/campaign/${campaignId}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadReceiptMetricsSuccess(response));
  } catch (err) {
    yield put(loadReceiptMetricsError(true));
  }
}

function selectEndDate(start, end) {
  const days = moment(end).diff(moment(start), 'days') + 1;
  const lastPeriod = days % 14;
  if (lastPeriod > 0) {
    const result = new Date(end);
    result.setDate(result.getDate() + (14 - lastPeriod));
    return result.toISOString().substring(0, 10);
  }
  return end;
}
