export const SET_EXPAND_ONBOARDING_KYC_CONTAINER =
  'app/OnboardingKYCContainer/SET_EXPAND';
export const ACCEPT_TERMS = 'app/OnboardingKYCContainer/ACCEPT_TERMS';
export const ACCEPT_TERMS_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/ACCEPT_TERMS_FOR_SECOND_APPLICANT';
export const ACCEPT_TERMS_SUCCESS =
  'app/OnboardingKYCContainer/ACCEPT_TERMS_SUCCESS';
export const GET_CURRENT_ITEM = 'app/OnboardingKYCContainer/GET_CURRENT_ITEM';
export const GET_CURRENT_ITEM_SUCCESS =
  'app/OnboardingKYCContainer/GET_CURRENT_ITEM_SUCCESS';
export const GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT =
  'app/OnboardingKYCContainer/GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT';
export const CONFIRM_EMAIL = 'app/OnboardingKYCContainer/CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS =
  'app/OnboardingKYCContainer/CONFIRM_EMAIL_SUCCESS';
export const VALIDATE_SECOND_APPLICANT_TOKEN =
  'app/OnboardingKYCContainer/VALIDATE_SECOND_APPLICANT_TOKEN';
export const VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS =
  'app/OnboardingKYCContainer/VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS';

export const SEARCH_BUSINESS = 'app/OnboardingKYCContainer/SEARCH_BUSINESS';
export const SEARCH_BUSINESS_SUCCESS =
  'app/OnboardingKYCContainer/SEARCH_BUSINESS_SUCCESS';
export const SEARCH_BUSINESS_ERROR =
  'app/OnboardingKYCContainer/SEARCH_BUSINESS_ERROR';
export const SEARCH_BUSINESS_RESET =
  'app/OnboardingKYCContainer/SEARCH_BUSINESS_RESET';
export const SELECT_BANK = 'app/OnboardingKYCContainer/SELECT_BANK';
export const GET_EMAIL = 'app/OnboardingKYCContainer/GET_EMAIL';
export const START_ORGANISATION_ONBOARDING =
  'app/OnboardingKYCContainer/START_ORGANISATION_ONBOARDING';
export const START_ORGANISATION_ONBOARDING_SUCCESS =
  'app/OnboardingKYCContainer/START_ORGANISATION_ONBOARDING_SUCCESS';
export const SEND_EMAIL = 'app/OnboardingKYCContainer/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS =
  'app/OnboardingKYCContainer/SEND_EMAIL_SUCCESS';
export const SET_PASSWORD = 'app/OnboardingKYCContainer/SET_PASSWORD';
export const SET_PASSWORD_SUCCESS =
  'app/OnboardingKYCContainer/SET_PASSWORD_SUCCESS';
export const SET_BAD_SECTOR = 'app/OnboardingKYCContainer/SET_BAD_SECTOR';
export const SUBMIT_BAD_SECTOR = 'app/OnboardingKYCContainer/SUBMIT_BAD_SECTOR';
export const SUBMIT_BAD_SECTOR_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_BAD_SECTOR_SUCCESS';
export const SAVE_FIRSTNAME = 'app/OnboardingKYCContainer/SAVE_FIRSTNAME';
export const SET_CONTACT_NAME = 'app/OnboardingKYCContainer/SET_CONTACT_NAME';
export const SET_CONTACT_NAME_SUCCESS =
  'app/OnboardingKYCContainer/SET_CONTACT_NAME_SUCCESS';
export const SEND_SMS = 'app/OnboardingKYCContainer/SEND_SMS';
export const SEND_SMS_SUCCESS = 'app/OnboardingKYCContainer/SEND_SMS_SUCCESS';
export const SAVE_PHONE_NUMBER = 'app/OnboardingKYCContainer/SAVE_PHONE_NUMBER';
export const RESEND_SMS = 'app/OnboardingKYCContainer/RESEND_SMS';
export const RESEND_SMS_SUCCESS =
  'app/OnboardingKYCContainer/RESEND_SMS_SUCCESS';
export const VERIFY_CODE = 'app/OnboardingKYCContainer/VERIFY_CODE';
export const VERIFY_CODE_SUCCESS =
  'app/OnboardingKYCContainer/VERIFY_CODE_SUCCESS';
export const GET_TERMS = 'app/OnboardingKYCContainer/GET_TERMS';
export const GET_TERMS_SUCCESS = 'app/OnboardingKYCContainer/GET_TERMS_SUCCESS';
export const SET_BIRTH_DATE = 'app/OnboardingKYCContainer/SET_BIRTH_DATE';
export const SUBMIT_BIRTH_DATE = 'app/OnboardingKYCContainer/SUBMIT_BIRTH_DATE';
export const SUBMIT_BIRTH_DATE_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_BIRTH_DATE_SUCCESS';
export const SELECT_COUNTRY = 'app/OnboardingKYCContainer/SELECT_COUNTRY';
export const SET_COUNTRY = 'app/OnboardingKYCContainer/SET_COUNTRY';
export const SET_COUNTRY_SUCCESS =
  'app/OnboardingKYCContainer/SET_COUNTRY_SUCCESS';
export const SET_COUNTRY_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/SET_COUNTRY_FOR_SECOND_APPLICANT';
export const SUBMIT_PARTIAL_ADDRESS =
  'app/OnboardingKYCContainer/SUBMIT_PARTIAL_ADDRESS';
export const SUBMIT_PARTIAL_ADDRESS_ERROR =
  'app/OnboardingKYCContainer/SUBMIT_PARTIAL_ADDRESS_ERROR';
export const SUBMIT_PARTIAL_ADDRESS_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_PARTIAL_ADDRESS_SUCCESS';
export const SUBMIT_ADDRESS = 'app/OnboardingKYCContainer/SUBMIT_ADDRESS';
export const SUBMIT_ADDRESS_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/SUBMIT_ADDRESS_FOR_SECOND_APPLICANT';
export const SUBMIT_ADDRESS_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_ADDRESS_SUCCESS';
export const GET_ISSUERS = 'app/OnboardingKYCContainer/GET_ISSUERS';
export const GET_ISSUERS_SUCCESS =
  'app/OnboardingKYCContainer/GET_ISSUERS_SUCCESS';
export const SET_BSN = 'app/OnboardingKYCContainer/SET_BSN';
export const SEND_BSN = 'app/OnboardingKYCContainer/SEND_BSN';
export const SEND_BSN_SUCCESS = 'app/OnboardingKYCContainer/SEND_BSN_SUCCESS';
export const SET_SECTOR = 'app/OnboardingKYCContainer/SET_SECTOR';
export const UPDATE_CURRENT_ITEM_NUMBER =
  'app/OnboardingKYCContainer/UPDATE_CURRENT_ITEM_NUMBER';
export const INITIATE_PERSONAL_PAYMENT =
  'app/OnboardingKYCContainer/INITIATE_PERSONAL_PAYMENT';
export const INITIATE_PERSONAL_PAYMENT_SUCCESS =
  'app/OnboardingKYCContainer/INITIATE_PERSONAL_PAYMENT_SUCCESS';
export const CHECK_PERSONAL_PAYMENT =
  'app/OnboardingKYCContainer/CHECK_PERSONAL_PAYMENT';
export const CHECK_PERSONAL_PAYMENT_SUCCESS =
  'app/OnboardingKYCContainer/CHECK_PERSONAL_PAYMENT_SUCCESS';
export const CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED =
  'app/OnboardingKYCContainer/CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED';
export const HANDLE_PENDING_PAYMENT =
  'app/OnboardingKYCContainer/HANDLE_PENDING_PAYMENT';
export const SET_PERSONAL_PAYMENT_TOKEN =
  'app/OnboardingKYCContainer/SET_PERSONAL_PAYMENT_TOKEN';
export const SKIP_BUSINESS_PAYMENT =
  'app/OnboardingKYCContainer/SKIP_BUSINESS_PAYMENT';
export const SKIP_BUSINESS_PAYMENT_SUCCESS =
  'app/OnboardingKYCContainer/SKIP_BUSINESS_PAYMENT_SUCCESS';
export const INITIATE_BUSINESS_PAYMENT =
  'app/OnboardingKYCContainer/INITIATE_BUSINESS_PAYMENT';
export const INITIATE_BUSINESS_PAYMENT_SUCCESS =
  'app/OnboardingKYCContainer/INITIATE_BUSINESS_PAYMENT_SUCCESS';
export const RESET_PASSWORD = 'app/OnboardingKYCContainer/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS =
  'app/OnboardingKYCContainer/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR =
  'app/OnboardingKYCContainer/RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_TOKEN =
  'app/OnboardingKYCContainer/RESET_PASSWORD_TOKEN';
export const RESET_RESET_PASSWORD =
  'app/OnboardingKYCContainer/RESET_RESET_PASSWORD';
export const CHECK_BUSINESS_PAYMENT =
  'app/OnboardingKYCContainer/CHECK_BUSINESS_PAYMENT';
export const CHECK_BUSINESS_PAYMENT_SUCCESS =
  'app/OnboardingKYCContainer/CHECK_BUSINESS_PAYMENT_SUCCESS';
export const CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED =
  'app/OnboardingKYCContainer/CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED';
export const SET_BUSINESS_PAYMENT_TOKEN =
  'app/OnboardingKYCContainer/SET_BUSINESS_PAYMENT_TOKEN';
export const SET_GENERIC_ERROR = 'app/OnboardingKYCContainer/SET_GENERIC_ERROR';
export const SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING =
  'app/OnboardingKYCContainer/SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING';
export const CLEAR_GENERIC_ERROR =
  'app/OnboardingKYCContainer/CLEAR_GENERIC_ERROR';
export const SET_GENERIC_PENDING =
  'app/OnboardingKYCContainer/SET_GENERIC_PENDING';
export const CLEAR_GENERIC_PENDING =
  'app/OnboardingKYCContainer/CLEAR_GENERIC_PENDING';
export const SET_NICKNAME = 'app/OnboardingKYCContainer/SET_NICKNAME';
export const SET_NICKNAME_SUCCESS =
  'app/OnboardingKYCContainer/SET_NICKNAME_SUCCESS';
export const SET_AUTHORIZED_TO_SIGN =
  'app/OnboardingKYCContainer/SET_AUTHORIZED_TO_SIGN';
export const SET_AUTHORIZED_TO_SIGN_SUCCESS =
  'app/OnboardingKYCContainer/SET_AUTHORIZED_TO_SIGN_SUCCESS';
export const CLEAR_SESSION = 'app/OnboardingKYCContainer/CLEAR_SESSION';
export const SUBMIT_UBO = 'app/OnboardingKYCContainer/SUBMIT_UBO';
export const SUBMIT_UBO_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_UBO_SUCCESS';
export const START_ADD_UBO = 'app/OnboardingKYCContainer/START_ADD_UBO';
export const START_EDIT_UBO = 'app/OnboardingKYCContainer/START_EDIT_UBO';
export const SET_UBO_NAME = 'app/OnboardingKYCContainer/SET_UBO_NAME';
export const SET_UBO_BIRTH_DATE =
  'app/OnboardingKYCContainer/SET_UBO_BIRTH_DATE';
export const SET_UBO_COUNTRY = 'app/OnboardingKYCContainer/SET_UBO_COUNTRY';
export const CLEAR_UBO_ADDRESS = 'app/OnboardingKYCContainer/CLEAR_UBO_ADDRESS';
export const SET_UBO_ADDRESS = 'app/OnboardingKYCContainer/SET_UBO_ADDRESS';
export const SUBMIT_PARTIAL_UBO_ADDRESS =
  'app/OnboardingKYCContainer/SUBMIT_PARTIAL_UBO_ADDRESS';
export const SET_INITIAL_GET_CURRENT_ITEM =
  'app/OnboardingKYCContainer/SET_INITIAL_GET_CURRENT_ITEM';
export const SET_POS_SUPPLIER = 'app/OnboardingKYCContainer/SET_POS_SUPPLIER';
export const SUBMIT_POS_DATA = 'app/OnboardingKYCContainer/SUBMIT_POS_DATA';
export const SUBMIT_POS_DATA_SUCCESS =
  'app/OnboardingKYCContainer/SUBMIT_POS_DATA_SUCCESS';
export const SET_KEEP_IN_TOUCH = 'app/OnboardingKYCContainer/SET_KEEP_IN_TOUCH';
export const SET_KEEP_IN_TOUCH_SUCCESS =
  'app/OnboardingKYCContainer/SET_KEEP_IN_TOUCH_SUCCESS';
export const SET_AAB_ONBOARDING =
  'app/OnboardingKYCContainer/SET_AAB_ONBOARDING';
export const SET_CDD_ONBOARDING =
  'app/OnboardingKYCContainer/SET_CDD_ONBOARDING';
export const SET_PRODUCT_TYPE = 'app/OnboardingKYCContainer/SET_PRODUCT_TYPE';
export const SET_ONBOARDING_FLOW =
  'app/OnboardingKYCContainer/SET_ONBOARDING_FLOW';
export const GET_POS_DATA = 'app/OnboardingKYCContainer/GET_POS_DATA';
export const GET_POS_DATA_SUCCESS =
  'app/OnboardingKYCContainer/GET_POS_DATA_SUCCESS';
export const GET_QR_CODE = 'app/OnboardingKYCContainer/GET_QR_CODE';
export const GET_QR_CODE_SUCCESS =
  'app/OnboardingKYCContainer/GET_QR_CODE_SUCCESS';
export const CREATE_TRANSACTION =
  'app/OnboardingKYCContainer/CREATE_TRANSACTION';
export const CREATE_TRANSACTION_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/CREATE_TRANSACTION_FOR_SECOND_APPLICANT';
export const CREATE_TRANSACTION_SUCCESS =
  'app/OnboardingKYCContainer/CREATE_TRANSACTION_SUCCESS';
export const GET_IDV_STATUS = 'app/OnboardingKYCContainer/GET_IDV_STATUS';
export const GET_IDV_STATUS_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/GET_IDV_STATUS_FOR_SECOND_APPLICANT';
export const GET_IDV_STATUS_SUCCESS =
  'app/OnboardingKYCContainer/GET_IDV_STATUS_SUCCESS';
export const GET_ITEM_AFTER_IDV =
  'app/OnboardingKYCContainer/GET_ITEM_AFTER_IDV';
export const GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT';
export const GET_ITEM_AFTER_IDV_SUCCESS =
  'app/OnboardingKYCContainer/GET_ITEM_AFTER_IDV_SUCCESS';
export const CLEAR_TRANSACTION_PROPS =
  'app/OnboardingKYCContainer/CLEAR_TRANSACTION_PROPS';
export const SET_APPLICANT_2_NAME =
  'app/OnboardingKYCContainer/SET_APPLICANT_2_NAME';
export const SET_IS_SECOND_APPLICANT =
  'app/OnboardingKYCContainer/SET_IS_SECOND_APPLICANT';
export const SECOND_APPLICANT_DETAILS =
  'app/OnboardingKYCContainer/SECOND_APPLICANT_DETAILS';
export const SECOND_APPLICANT_DETAILS_SUCCESS =
  'app/OnboardingKYCContainer/SECOND_APPLICANT_DETAILS_SUCCESS';
export const SET_RISK_ANSWERS = 'app/OnboardingKYCContainer/SET_RISK_ANSWERS';
export const SEND_RISK_ANSWERS = 'app/OnboardingKYCContainer/SEND_RISK_ANSWERS';
export const SEND_RISK_ANSWERS_SUCCESS =
  'app/OnboardingKYCContainer/SEND_RISK_ANSWERS_SUCCESS';
