import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReactBodymovin from 'react-bodymovin';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import 'react-dates/initialize';
import { BlokkieTextLink } from '@tikkie/blokkie';

import 'react-dates/lib/css/_datepicker.css';
import '!file-loader?name=[name].[ext]!../../../images/voorbeeld.xlsx';
import '!file-loader?name=[name].[ext]!../../../images/downloadicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/uploadicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/fileok.svg';
import '!file-loader?name=[name].[ext]!../../../images/trash.svg';
import messages from './messages';
import { isEmpty } from '../../../utils';
import animationData from '../../../images/animations/green-dots.json';
import animationDataWhite from '../../../images/animations/white-dots.json';
import {
  Caption,
  Color,
  Header1,
  Header2,
  Panel,
  PrimaryButton,
  SecondaryButton,
} from '../../styleguide';
import { BackButton, BigErrorBubble } from '../../components';
import PreviewList from '../PreviewList';
import { usePrevious } from '../../../hooks';

const BulkCreateWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;
`;

const Header = styled(Header1)`
  margin-top: 27px;
  margin-bottom: 10px;
`;

const Subtitle = styled(Caption)`
  margin-bottom: 14px;
`;

const DownloadsWrapper = styled.div`
  margin-bottom: 24px;
`;

const DownloadButton = styled.a`
  margin-top: 16px;
  margin-right: 20px;
  line-height: 22px;
  display: inline-block;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.brandColor01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  text-decoration: none;

  img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
`;

const UploadWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
  box-shadow: none;
  min-height: 212px;
`;

const ErrorDetailWrapper = styled(Panel)`
  position: relative;
  margin-top: 24px;
  box-shadow: none;
  padding: 32px;
`;

const ErrorDetailTable = styled.table`
  padding-left: 32px;
  width: 100%;
`;

const ErrorDetailTableRow = styled.tr``;

const ErrorDetailTableHeader = styled.thead``;

const ErrorDetailTableBody = styled.tbody``;

const ErrorDetailTableHeaderTitle = styled.th`
  padding: 4px;
`;

const ErrorDetailTableData = styled.td`
  padding: 4px;
`;

const UploadReady = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 60px 0px 0px 0px;
  border-radius: 8px;
  background-color: #f9f9fc;
  box-shadow: none;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  text-align: center;
  color: ${Color.gray01};

  b {
    font-size: 40px;
  }
`;

const UploadHeader = styled(Header2)`
  text-align: center;
  margin-bottom: 8px;
`;

const UploadCaption = styled(Caption)`
  display: block;
  margin-top: 16px;
  margin-bottom: 24px;
`;

const UploadButton = styled(PrimaryButton)``;

const PreviewWrapper = styled(UploadWrapper)`
  padding-top: 55px;
  padding-bottom: 55px;
  text-align: center;
  background-color: #f9f9fc;
  box-shadow: none;
`;

const FileWrapper = styled.div`
  display: inline-block;
  width: 554px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${Color.white};
`;

const FileIcon = styled.img`
  float: left;
  padding-right: 24px;
`;

const FileTitle = styled(Header2)`
  display: block;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeleteIcon = styled.img``;

const DeleteButton = styled.button`
  float: right;
  width: 42px;
  height: 42px;
  margin-right: 24px;
  margin-left: 32px;
  padding-left: 5px;
  border-radius: 6.4px;
  border: solid 2px ${Color.gray05};
  cursor: pointer;
`;

const ErrorWrapper = styled.div`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  text-align: right;
`;

const AnimationWrapper = styled.div`
  height: 212px;
  padding-top: 95px;
  padding-bottom: 40px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const SendingAnimationWrapper = styled.div`
  text-align: center;
  .react-bodymovin-container {
    height: 18px;
  }
`;

const SendButton = styled(PrimaryButton)`
  width: auto;
`;

const SaveButton = styled(SecondaryButton)`
  width: 291px;
  border-radius: 24px;
  margin-right: 16px;
`;

const DropStyle = {
  paddingTop: '48px',
  paddingBottom: '48px',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: 'white',
  transition: 'opacity 0.2s',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
};

const AcceptDropStyle = {
  paddingTop: '48px',
  paddingBottom: '48px',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: '#EFFAF9',
  opacity: '0',
};

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

const bodymovinOptionsWhite = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: animationDataWhite,
};

// Make sure the dates are in Dutch
moment.locale('nl');

function BulkCreate({
  location,
  intl,
  isUploading,
  bulkUploadErrorMessage,
  bulkUploadValidationErrors,
  bulkUploadFile,
  bulkUpload,
  isDeletingBulkUpload,
  deleteBulkUploadErrorMessage,
  deleteBulkUpload,
  isSendingBulkUpload,
  sendBulkUploadErrorMessage,
  sendBulkUpload,
  showDashboard,
  showBulkList,
  emptyUploadFile,
  resetBulkUpload,
  showTikkieDetail,
}) {
  const bulkUploadExcelErrorMessage = intl.formatMessage({
    id: 'app.components.BulkCreate.bulkUploadExcelErrorMessage',
  });
  const bulkUploadNonCsvErrorMessage = intl.formatMessage({
    id: 'app.components.BulkCreate.bulkUploadNonCsvErrorMessage',
  });
  const rejectBulkUploadErrorMessage = intl.formatMessage({
    id: 'app.components.BulkCreate.rejectBulkUploadErrorMessage',
  });

  const [isExcelUploaded, setIsExcelUploaded] = useState(false);
  const [isOtherFileUploaded, setIsOtherFileUploaded] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const prevIsSendingBulkUpload = usePrevious(isSendingBulkUpload);

  useEffect(() => {
    if (
      prevIsSendingBulkUpload &&
      !isSendingBulkUpload &&
      sendBulkUploadErrorMessage === null
    ) {
      handleBulkList();
    }
  }, [isSendingBulkUpload, sendBulkUploadErrorMessage]);

  const hasFileName = !isEmpty(bulkUploadFile.fileName);
  const hasValidationErrors =
    !!bulkUploadValidationErrors && bulkUploadValidationErrors.length > 0;
  const isUploadFormVisible = !isUploading && !hasFileName;
  const isSpinnerVisible = isUploading || isDeletingBulkUpload;
  const isUploadFileVisible = !isEmpty(bulkUploadFile) && hasFileName;
  const isUploadErrorVisible = !!bulkUploadErrorMessage;
  const isDeleteErrorVisible = !!deleteBulkUploadErrorMessage;
  const isSendErrorVisible = !!sendBulkUploadErrorMessage;
  const isExcelFileError = isExcelUploaded;
  const isNonCSVFileError = isOtherFileUploaded;
  const isRejectErrorVisible = isRejected;
  const hasNoFile = isEmpty(bulkUploadFile);

  const onDrop = useCallback((files) => {
    setIsExcelUploaded(false);
    setIsOtherFileUploaded(false);
    setIsRejected(false);

    files.forEach((file) => {
      if (file.name.toLowerCase().endsWith('.csv')) {
        bulkUpload(file);
      } else if (file.name.toLowerCase().endsWith('.xls')) {
        setIsExcelUploaded(true);
      } else if (file.name.toLowerCase().endsWith('.xlsx')) {
        setIsExcelUploaded(true);
      } else {
        setIsOtherFileUploaded(true);
      }
    });
  }, []);

  const onDropRejected = useCallback(() => {
    setIsRejected(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
  });

  const handleBulkList = () => {
    showBulkList();
    emptyUploadFile();
  };

  const handleClick = () => {
    showDashboard();
    resetBulkUpload();
    emptyUploadFile();
  };

  return (
    <BulkCreateWrapper>
      <BackButton
        id="/backToOverview"
        text={<FormattedMessage {...messages.back} />}
        onClick={handleClick}
      />
      <Header>
        <FormattedMessage {...messages.header} />
      </Header>
      <Subtitle>
        <FormattedMessage {...messages.caption1} />
        <BlokkieTextLink
          href="https://www.tikkie.me/zakelijk/portal-handleiding#bulk"
          target="_blank"
          variant="p700"
          underline
        >
          <FormattedMessage {...messages.caption2} />
        </BlokkieTextLink>
        <FormattedMessage {...messages.caption3} />
      </Subtitle>
      <DownloadsWrapper>
        <DownloadButton target="_blank" href="voorbeeld.xlsx">
          <img src="/downloadicon.svg" alt="" />
          <FormattedMessage {...messages.downloadExample} />
        </DownloadButton>
      </DownloadsWrapper>
      {isUploadFormVisible && (
        <UploadWrapper>
          <div
            {...getRootProps()}
            style={isDragActive ? { AcceptDropStyle } : { DropStyle }}
          >
            <UploadReady>
              {isDragActive ? (
                <Fragment>
                  <b>
                    <span role="img" aria-label="hi">
                      👋
                    </span>
                  </b>
                  <br />
                  <br />
                  <FormattedMessage {...messages.dropFile} />
                </Fragment>
              ) : (
                <Fragment>
                  <UploadHeader>
                    <FormattedMessage {...messages.uploadFileHeader} />
                  </UploadHeader>
                  <UploadCaption>
                    <FormattedMessage {...messages.uploadFileCaption} />
                  </UploadCaption>
                  <UploadButton>
                    <FormattedMessage {...messages.uploadFileButton} />
                  </UploadButton>
                  <input {...getInputProps()} />
                </Fragment>
              )}
            </UploadReady>
          </div>
          {isUploadErrorVisible && (
            <ErrorWrapper>
              <BigErrorBubble text={bulkUploadErrorMessage} />
            </ErrorWrapper>
          )}
          {isExcelFileError && (
            <ErrorWrapper>
              <BigErrorBubble text={bulkUploadExcelErrorMessage} />
            </ErrorWrapper>
          )}
          {isNonCSVFileError && (
            <ErrorWrapper>
              <BigErrorBubble text={bulkUploadNonCsvErrorMessage} />
            </ErrorWrapper>
          )}
          {isDeleteErrorVisible && (
            <ErrorWrapper>
              <BigErrorBubble text={deleteBulkUploadErrorMessage} />
            </ErrorWrapper>
          )}
          {isSendErrorVisible && (
            <ErrorWrapper>
              <BigErrorBubble text={sendBulkUploadErrorMessage} />
            </ErrorWrapper>
          )}
          {isRejectErrorVisible && (
            <ErrorWrapper>
              <BigErrorBubble text={rejectBulkUploadErrorMessage} />
            </ErrorWrapper>
          )}
        </UploadWrapper>
      )}
      {isSpinnerVisible && (
        <UploadWrapper>
          <AnimationWrapper>
            <ReactBodymovin options={bodymovinOptions} />
          </AnimationWrapper>
        </UploadWrapper>
      )}
      {isUploadFileVisible && (
        <PreviewWrapper>
          <FileWrapper>
            <FileIcon src="/fileok.svg" />
            <DeleteButton onClick={() => deleteBulkUpload(bulkUploadFile.id)}>
              <DeleteIcon src="/trash.svg" />
            </DeleteButton>
            <FileTitle>{bulkUploadFile.fileName}</FileTitle>
          </FileWrapper>
        </PreviewWrapper>
      )}
      <ButtonWrapper>
        <SaveButton onClick={handleBulkList} disabled={hasNoFile}>
          <FormattedMessage {...messages.saveTikkiesButton} />
        </SaveButton>
        {!isSendingBulkUpload && (
          <SendButton
            onClick={() => sendBulkUpload(bulkUploadFile.id)}
            disabled={hasNoFile}
            id="sendTikkies"
          >
            <img src="/uploadicon-o.svg" alt="" />
            <FormattedMessage {...messages.sendTikkiesButton} />
          </SendButton>
        )}
        {isSendingBulkUpload && (
          <SendButton>
            <SendingAnimationWrapper>
              <ReactBodymovin options={bodymovinOptionsWhite} />
            </SendingAnimationWrapper>
          </SendButton>
        )}
      </ButtonWrapper>
      {hasValidationErrors && (
        <>
          <Header>
            <FormattedMessage {...messages.errorDetailsHeader} />
          </Header>
          <Subtitle>
            <FormattedMessage {...messages.errorDetailsSubtitle} />
          </Subtitle>
          <ErrorDetailWrapper>
            <ErrorDetailTable>
              <ErrorDetailTableHeader>
                <ErrorDetailTableRow>
                  <ErrorDetailTableHeaderTitle>
                    <FormattedMessage
                      {...messages.errorDetailsTableErrorHeading}
                    />
                  </ErrorDetailTableHeaderTitle>
                  <ErrorDetailTableHeaderTitle>
                    <FormattedMessage
                      {...messages.errorDetailsTableColumnHeading}
                    />
                  </ErrorDetailTableHeaderTitle>
                  <ErrorDetailTableHeaderTitle>
                    <FormattedMessage
                      {...messages.errorDetailsTableRowHeading}
                    />
                  </ErrorDetailTableHeaderTitle>
                </ErrorDetailTableRow>
              </ErrorDetailTableHeader>
              <ErrorDetailTableBody>
                {bulkUploadValidationErrors.map((bulkErr) => (
                  <ErrorDetailTableRow
                    key={`${bulkErr.errorCode}-${bulkErr.lineNumber}-${bulkErr.columnName}`}
                  >
                    <ErrorDetailTableData>
                      {bulkErr.userMessage}
                    </ErrorDetailTableData>
                    <ErrorDetailTableData>
                      {bulkErr.columnName}
                    </ErrorDetailTableData>
                    <ErrorDetailTableData>
                      {bulkErr.lineNumber}
                    </ErrorDetailTableData>
                  </ErrorDetailTableRow>
                ))}
              </ErrorDetailTableBody>
            </ErrorDetailTable>
          </ErrorDetailWrapper>
        </>
      )}
      {isUploadFileVisible && (
        <PreviewList
          bulkFile={bulkUploadFile}
          goToPreview={showTikkieDetail}
          location={location}
        />
      )}
    </BulkCreateWrapper>
  );
}

BulkCreate.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object.isRequired,
  isUploading: PropTypes.bool.isRequired,
  bulkUploadErrorMessage: PropTypes.string,
  bulkUploadValidationErrors: PropTypes.array,
  bulkUploadFile: PropTypes.object,
  bulkUpload: PropTypes.func.isRequired,
  isDeletingBulkUpload: PropTypes.bool.isRequired,
  deleteBulkUploadErrorStatus: PropTypes.number.isRequired,
  deleteBulkUploadErrorMessage: PropTypes.string,
  deleteBulkUpload: PropTypes.func.isRequired,
  isSendingBulkUpload: PropTypes.bool.isRequired,
  sendBulkUploadErrorStatus: PropTypes.number.isRequired,
  sendBulkUploadErrorMessage: PropTypes.string,
  sendBulkUpload: PropTypes.func.isRequired,
  showDashboard: PropTypes.func.isRequired,
  showBulkList: PropTypes.func.isRequired,
  emptyUploadFile: PropTypes.func.isRequired,
  resetBulkUpload: PropTypes.func.isRequired,
  showTikkieDetail: PropTypes.func.isRequired,
};

export default injectIntl(BulkCreate);
