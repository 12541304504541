import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from 'recharts';
import moment from 'moment';

import '!file-loader?name=[name].[ext]!../../../images/nexticon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/previcon-o.svg';

import Loader from '../../OnboardingKYC/Loader';
import { Color, Caption, CaptionSmall, Space } from '../../styleguide';
import messages from './messages';
import { findFirstWeekDayOnOrBefore } from '../../../utils';

const Row = styled.div`
  display: flex;
`;
const Button = styled.img`
  ${(props) => (props.disabled ? 'opacity: 50%;' : 'cursor: pointer;')};
`;

const metricRejectCategories = {
  RECEIPT_DUPLICATE: messages.scanReceiptDuplicate,
  RECEIPT_DATE_MISMATCH: messages.scanReceiptDateMismatch,
  RECEIPT_DATE_MISSING: messages.scanReceiptDateMissing,
  PRODUCT_NOT_FOUND: messages.scanProductNotFound,
  PRICE_MISMATCH: messages.scanPriceMismatch,
  OCR_CONFIDENCE: messages.scanOcrConfidence,
  // any other error category will count to OTHER (Anders).
};

const TooltipContent = styled.div`
  font-family: MuseoSansRounded700;
  margin: 0;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 2px solid #f2f2f3;
  box-shadow: 4px 10px 24px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
`;
const TooltipCaption = styled(Caption)`
  color: ${Color.gray01};
  margin-bottom: ${Space.s16};
  display: block;
`;
const TooltipRow = styled.div`
  display: flex;
  margin-bottom: ${Space.s16};
`;
const TooltipItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${Space.s8};
`;
const TooltipItemCaption = styled(CaptionSmall)``;
const TooltipItemValue = styled(Caption)`
  color: ${Color.gray01};
`;
const TooltipItemDetailRow = styled.tr`
  color: ${Color.gray01};
`;
const TooltipItemDetailValue = styled.td`
  font-size: 16px;
  padding-right: ${Space.s16};
  color: ${Color.gray01};
  text-align: right;
`;
const TooltipItemDetailCaption = styled.td`
  font-size: 16px;
  color: ${Color.gray01};
`;

function CustomizedAxisTick(props) {
  const { x, y, payload, index } = props;
  if ((index + 2) % 3 === 0) {
    const date = moment(new Date(payload.value));
    const label = `${date.format('ddd D\xA0MMM')}`;

    return (
      <Text x={x} y={y} width={50} textAnchor="middle" verticalAnchor="start">
        {label}
      </Text>
    );
  }
  return <Text />;
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  index: PropTypes.number,
};

export default class ReceiptScansChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysPerChart: 14,
      startDay: 0,
    };
  }

  render() {
    const { props } = this;
    if (
      props.campaign &&
      props.campaign.id &&
      !props.receiptMetrics &&
      !props.receiptMetricsFetching
      // this also reloads in case the previous load returned in an error,
      // so assumes that a reload will eventually fix the error
    ) {
      props.loadReceiptMetrics(props.campaign);
      this.setState({ startDay: computeInitialStartDay(props.campaign) });
    }
    if (
      props.receiptMetricsFetching ||
      props.receiptMetricsError ||
      !props.receiptMetrics
    ) {
      return <Loader />;
    }
    const prevPeriod = () =>
      this.setState((prevState) => ({
        startDay: Math.max(0, prevState.startDay - 14),
      }));
    const nextPeriod = () =>
      this.setState((prevState) => ({
        startDay: Math.min(
          this.props.receiptMetrics
            ? this.props.receiptMetrics.data.length - 14
            : 0,
          prevState.startDay + 14,
        ),
      }));
    const data = this.computeChartData();
    return (
      <Row>
        <Button
          src="/previcon-o.svg"
          alt=""
          onClick={prevPeriod}
          disabled={this.isFirst()}
        />
        <BarChart
          width={948}
          height={494}
          data={data}
          margin={{ top: 10, right: 30, left: 20, bottom: 30 }}
          barCategoryGap="75%"
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tick={<CustomizedAxisTick />}
            interval={0}
            height={50}
          />
          <YAxis orientation="right" axisLine={false} tickLine={false} />
          <Tooltip
            content={this.renderTooltip(data)}
            cursor={{ fill: Color.gray08 }}
          />
          <Legend
            margin={{ top: 20, left: 0, right: 0, bottom: 20 }}
            iconType="circle"
          />
          <Bar
            name={messages.scanOk.defaultMessage}
            dataKey="OK"
            fill="#39A4A1"
            stackId="a"
          />
          <Bar
            name={messages.scanNotOk.defaultMessage}
            dataKey="NOT_OK"
            fill="#C4E7E4"
            stackId="a"
          />
        </BarChart>
        <Button
          src="/nexticon-o.svg"
          alt=""
          onClick={nextPeriod}
          disabled={this.isLast()}
        />
      </Row>
    );
  }

  /* eslint-disable no-restricted-syntax */
  computeChartData() {
    return this.props.receiptMetrics.data
      .slice(this.state.startDay, this.state.startDay + this.state.daysPerChart)
      .map((row) => {
        const result = {};
        result.date = row.date;
        result.OK = row.validations.OK || 0;
        for (const cat of Object.keys(metricRejectCategories)) {
          result[cat] = row.validations[cat] || 0;
        }
        let other = 0;
        let notOk = 0;
        for (const cat of Object.keys(row.validations)) {
          if (
            cat !== 'OK' &&
            cat !== 'date' &&
            typeof row.validations[cat] === 'number'
          ) {
            if (!metricRejectCategories[cat]) other += row.validations[cat];
            notOk += row.validations[cat];
          }
        }
        result.OTHER = other;
        result.NOT_OK = notOk;
        return result;
      });
  }
  /* eslint-enable no-restricted-syntax */

  isFirst() {
    return this.state.startDay === 0;
  }

  isLast() {
    return (
      this.props.receiptMetrics &&
      this.state.startDay + 14 >= this.props.receiptMetrics.data.length
    );
  }

  /* eslint-disable no-restricted-syntax */
  renderTooltip(receiptMetrics) {
    return (payload) => {
      const row = getRow(receiptMetrics, payload.label);
      const date = moment(payload.label);
      const detailRows = [];
      for (const metric of Object.keys(metricRejectCategories)) {
        detailRows.push(
          <TooltipItemDetailRow key={metric}>
            <TooltipItemDetailValue>{row[metric]}</TooltipItemDetailValue>
            <TooltipItemDetailCaption>
              <FormattedMessage {...metricRejectCategories[metric]} />
            </TooltipItemDetailCaption>
          </TooltipItemDetailRow>,
        );
      }
      detailRows.push(
        <TooltipItemDetailRow key="OTHER">
          <TooltipItemDetailValue>{row.OTHER}</TooltipItemDetailValue>
          <TooltipItemDetailCaption>
            <FormattedMessage {...messages.scanOther} />
          </TooltipItemDetailCaption>
        </TooltipItemDetailRow>,
      );
      return (
        <TooltipContent class="recharts-default-tooltip">
          <TooltipCaption>{date.format('dddd LL')}</TooltipCaption>
          <TooltipRow>
            <TooltipItem>
              <TooltipItemCaption>
                <FormattedMessage {...messages.scanOk} />
              </TooltipItemCaption>
              <TooltipItemValue>{row.OK}</TooltipItemValue>
            </TooltipItem>
            <TooltipItem>
              <TooltipItemCaption>
                <FormattedMessage {...messages.scanNotOk} />
              </TooltipItemCaption>
              <TooltipItemValue>{row.NOT_OK}</TooltipItemValue>
            </TooltipItem>
          </TooltipRow>
          <CaptionSmall>
            <FormattedMessage {...messages.scanRejectReason} />
          </CaptionSmall>
          <table>
            <tbody>{detailRows}</tbody>
          </table>
        </TooltipContent>
      );
    };
  }
  /* eslint-enable no-restricted-syntax */
}

/* eslint-disable no-restricted-syntax */
function getRow(data, label) {
  if (!data) return {};
  for (const row of data) {
    if (row.date === label) return row;
  }
  return {};
}
/* eslint-enable no-restricted-syntax */

function computeInitialStartDay(campaign) {
  const today = new Date().toISOString().substring(0, 10);
  // if campaign is not active yet, start chart at first days:
  if (today <= campaign.startDate) return 0;
  // compute actual start day we show, and the amount of days we show
  // (we always show from a monday in 14-days periods):
  const startDay = findFirstWeekDayOnOrBefore(campaign.startDate, 1);
  const endDateScans = today <= campaign.endDate ? today : campaign.endDate;
  let days = moment(endDateScans).diff(moment(startDay), 'days') + 1;
  if (days % 14) days += 14 - (days % 14);
  // compute which day we want to show (either today, or the last day if today is not in the days we show):
  const showDay = Math.min(
    days - 1,
    moment(today).diff(moment(startDay), 'days'),
  );
  // the day we start the chart should be dividable by 14:
  return showDay - (showDay % 14);
}

ReceiptScansChart.propTypes = {
  campaign: PropTypes.object,
  receiptMetrics: PropTypes.object,
  receiptMetricsFetching: PropTypes.bool,
  receiptMetricsError: PropTypes.bool,
  loadReceiptMetrics: PropTypes.func.isRequired,
};
