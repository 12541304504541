import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { BlokkieTextLink, blokkieTheme } from '@tikkie/blokkie';

import messages from './messages';
import { getCurrentYear } from '../../../utils';

function FooterComponent(): JSX.Element {
  const classes = useStyles();
  const footerLinkStyle = {
    borderBottom: 'none',
    marginRight: blokkieTheme.spacing(4),
    textDecoration: 'none',
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerInner}>
        <BlokkieTextLink
          href="https://tikkie.me/terms/terms_nl_NL.pdf"
          target="_blank"
          variant="pm700"
          color={blokkieTheme.colors.grey.white}
          rel="noopener"
          data-test-id="Onboarding-Form-TermsAndConditions"
          style={footerLinkStyle}
        >
          <FormattedMessage {...messages.termsAndConditions} />
        </BlokkieTextLink>
        <BlokkieTextLink
          href="https://www.tikkie.me/privacyverklaring"
          target="_blank"
          variant="pm700"
          color={blokkieTheme.colors.grey.white}
          rel="noopener"
          data-test-id="Onboarding-Form-Privacy"
          style={footerLinkStyle}
        >
          <FormattedMessage {...messages.privacy} />
        </BlokkieTextLink>
        <BlokkieTextLink
          href="/"
          variant="pm700"
          color={blokkieTheme.colors.grey.white}
          data-test-id="Onboarding-Form-Copyright"
          style={{
            color: blokkieTheme.colors.grey.white,
            borderBottom: 'none',
            flex: 1,
            textAlign: 'right',
            textDecoration: 'none',
          }}
        >
          <>
            <FormattedMessage {...messages.copyright1} />
            {getCurrentYear()}
            <FormattedMessage {...messages.copyright2} />
          </>
        </BlokkieTextLink>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  footer: {
    height: '57px',
    width: '100%',
    backgroundColor: 'rgba(52, 52, 109, 0.4)',
    alignSelf: 'flex-end',
    order: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'none',
    },
    [blokkieTheme.mediaQueries.tablet]: {
      display: 'none',
    },
  },
  footerInner: {
    width: '1031px',
    display: 'flex',
  },
});

export default FooterComponent;
