/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '!file-loader?name=[name].[ext]!../../../images/settings/settings.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/settings-payments.png';
import '!file-loader?name=[name].[ext]!../../../images/settings/settings-api.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/personalise.svg';
import '!file-loader?name=[name].[ext]!../../../images/notification.svg';
import '!file-loader?name=[name].[ext]!../../../images/cart.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/upload.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/sms.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/audit-log-settings.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/user-management-settings.svg';
import { createUseStyles } from 'react-jss';
import {
  BlokkieTypography,
  BlokkieTextLink,
  blokkieTheme,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import {
  PageTitle,
  Header2,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardTitle,
  CardBody,
  Paragraph,
  theme,
} from 'amber';
import { useSelector } from 'react-redux';
import { ErrorPageWithContact, ErrorPageNoContact } from '../styledComponents';
import config from '../../../config';
import { SettingsData } from './constants';
import { selectUser } from '../../../containers/App/selectors';
import { ROLE_CONTACT_PERSON } from '../../../global-constants';

interface SettingsDashboardProps {
  isContactPersonOrAdmin: boolean;
  gotoPortalOverview(): void;
  gotoBulkUploadSettings(): void;
  gotoOrganisationSettings(): void;
  goToPaymentSettings(): void;
  gotoPagesSettings(): void;
  gotoApiSettings(): void;
  gotoSmsSettings(): void;
  goToDeactivationSettings(): void;
  gotoAuditLog(): void;
  gotoUserManagementUsersList(): void;
  settingsData: SettingsData;
  settingsLoadingError: boolean;
  settingsLoadingErrorStatusCode?: number;
}

function SettingsDashboard({
  isContactPersonOrAdmin,
  gotoPortalOverview,
  gotoBulkUploadSettings,
  gotoOrganisationSettings,
  goToPaymentSettings,
  gotoPagesSettings,
  gotoApiSettings,
  gotoSmsSettings,
  goToDeactivationSettings,
  gotoAuditLog,
  gotoUserManagementUsersList,
  settingsData,
  settingsLoadingError,
  settingsLoadingErrorStatusCode,
}: SettingsDashboardProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const bulkOption = settingsData.bulkEnabled;
  const smsOption = settingsData.sms && settingsData.sms.enabled;

  const getStatusMessage = (status: boolean) =>
    status
      ? t('portal.settingsDashboard.status.on')
      : t('portal.settingsDashboard.status.off');

  const goToDeactivationPage = () => {
    goToDeactivationSettings();
  };

  if (settingsLoadingError) {
    if (settingsLoadingErrorStatusCode === 403) {
      return ErrorPageNoContact({
        pageTitle: t('portal.settingsDashboard.header.title'),
        showUnauthorizedDescription: true,
        onClickBackOverview: gotoPortalOverview,
      });
    }
    return ErrorPageWithContact({
      pageTitle: t('portal.settingsDashboard.header.title'),
      onClickBackOverview: gotoPortalOverview,
    });
  }

  return (
    <div className={classes.settingsPageContainer}>
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <PageTitle>{t('portal.settingsDashboard.header.title')}</PageTitle>
        <Container col={6} mb={4}>
          <Paragraph>
            {t('portal.settingsDashboard.header.description')}
          </Paragraph>
        </Container>
        <Container>
          <Paragraph>
            <BlokkieTextLink
              href="https://www.tikkie.me/zakelijk/portal-handleiding"
              target="_blank"
              variant="pl500"
              underline
            >
              {t('portal.settingsDashboard.header.instructions')}
            </BlokkieTextLink>
          </Paragraph>
        </Container>
        <Container row mt={32}>
          <Container
            col={4}
            colT={12}
            colM={12}
            pr={16}
            prT={0.1}
            prM={0.1}
            pbT={16}
          >
            <Card
              dataTestId="Dashboard-OrganisationSettingsTile"
              onClick={gotoOrganisationSettings}
            >
              <Container row mt={32} mb={32}>
                <CardMedia src="/settings.svg" rounded={false} middle />
                <CardContent center>
                  <CardTitle>
                    {t('portal.settingsDashboard.settings.organisation.title')}
                  </CardTitle>
                  <CardBody>
                    <Paragraph>
                      {t(
                        'portal.settingsDashboard.settings.organisation.description',
                      )}
                    </Paragraph>
                  </CardBody>
                </CardContent>
              </Container>
            </Card>
          </Container>

          <Container
            col={4}
            colT={12}
            colM={12}
            pr={16}
            prT={0.1}
            prM={0.1}
            pbT={16}
          >
            <Card
              dataTestId="Dashboard-PaymentSettingsTile"
              onClick={goToPaymentSettings}
            >
              <Container row mt={32} mb={32}>
                <CardMedia
                  src="/settings-payments.png"
                  rounded={false}
                  middle
                />
                <CardContent center>
                  <CardTitle>
                    {t('portal.settingsDashboard.settings.payment.title')}
                  </CardTitle>
                  <CardBody>
                    <Paragraph>
                      {t(
                        'portal.settingsDashboard.settings.payment.description',
                      )}
                    </Paragraph>
                  </CardBody>
                </CardContent>
              </Container>
            </Card>
          </Container>

          {config.get(config.PAGES_SETTINGS_ENABLED) && (
            <Container
              col={4}
              colT={12}
              colM={12}
              pr={16}
              prT={0.1}
              prM={0.1}
              pbT={16}
            >
              <Card
                dataTestId="Dashboard-PagesSettingsTile"
                onClick={gotoPagesSettings}
              >
                <Container row mt={32} mb={32}>
                  <CardMedia src="/personalise.svg" rounded={false} middle />
                  <CardContent center>
                    <CardTitle>
                      {t('portal.settingsDashboard.settings.pages.title')}
                    </CardTitle>
                    <CardBody>
                      <Paragraph>
                        {t(
                          'portal.settingsDashboard.settings.pages.description',
                        )}
                      </Paragraph>
                    </CardBody>
                  </CardContent>
                </Container>
              </Card>
            </Container>
          )}

          <Container
            col={4}
            colT={12}
            colM={12}
            pr={16}
            prT={0.1}
            prM={0.1}
            pbT={16}
            mt={19}
          >
            <Card
              dataTestId="Dashboard-ApiSettingsTile"
              onClick={gotoApiSettings}
            >
              <Container row mt={32} mb={32}>
                <CardMedia src="/settings-api.svg" rounded={false} middle />
                <CardContent center>
                  <CardTitle>
                    {t('portal.settingsDashboard.settings.api.title')}
                  </CardTitle>
                  <CardBody>
                    <Paragraph>
                      {t('portal.settingsDashboard.settings.api.description')}
                    </Paragraph>
                  </CardBody>
                </CardContent>
              </Container>
            </Card>
          </Container>

          {config.get(config.USER_MANAGEMENT_ENABLED) &&
            isContactPersonOrAdmin && (
              <Container
                col={4}
                colT={12}
                colM={12}
                pr={16}
                prT={0.1}
                prM={0.1}
                pbT={16}
                mt={19}
              >
                <Card
                  dataTestId="Dashboard-UserManagementTile"
                  onClick={gotoUserManagementUsersList}
                >
                  <Container row mt={32} mb={32}>
                    <CardMedia
                      src="/user-management-settings.svg"
                      rounded={false}
                      middle
                    />
                    <CardContent center>
                      <CardTitle>
                        {t(
                          'portal.settingsDashboard.settings.userManagement.title',
                        )}
                      </CardTitle>
                      <CardBody>
                        <Paragraph>
                          {t(
                            'portal.settingsDashboard.settings.userManagement.description',
                          )}
                        </Paragraph>
                      </CardBody>
                    </CardContent>
                  </Container>
                </Card>
              </Container>
            )}

          {config.get(config.AUDIT_LOG_ENABLED) && (
            <Container
              col={4}
              colT={12}
              colM={12}
              pr={16}
              prT={0.1}
              prM={0.1}
              pbT={16}
              mt={19}
            >
              <Card dataTestId="Dashboard-AuditLogTile" onClick={gotoAuditLog}>
                <Container row mt={32} mb={32}>
                  <CardMedia
                    src="/audit-log-settings.svg"
                    rounded={false}
                    middle
                  />
                  <CardContent center>
                    <CardTitle>
                      {t('portal.settingsDashboard.settings.auditLog.title')}
                    </CardTitle>
                    <CardBody>
                      <Paragraph>
                        {t(
                          'portal.settingsDashboard.settings.auditLog.description',
                        )}
                      </Paragraph>
                    </CardBody>
                  </CardContent>
                </Container>
              </Card>
            </Container>
          )}
        </Container>
        <Container row mt={24} mb={24}>
          <Container pt={8} pb={8}>
            <Header2>{t('portal.settingsDashboard.modules.title')}</Header2>
          </Container>
        </Container>
        <Container row>
          <Container
            col={4}
            colT={12}
            colM={12}
            pr={16}
            prT={0.1}
            prM={0.1}
            pbT={16}
          >
            <Card
              dataTestId="Dashboard-BulkUploadSettingsTile"
              onClick={gotoBulkUploadSettings}
            >
              <div className={classes.statusContainer}>
                <div className={classes.statusContainer}>
                  <div
                    className={[
                      classes.status,
                      bulkOption ? classes.statusOn : classes.statusOff,
                    ].join(' ')}
                  >
                    {getStatusMessage(bulkOption)}
                  </div>
                </div>
              </div>
              <Container row mb={32}>
                <CardMedia src="/upload.svg" rounded={false} middle />
                <CardContent center>
                  <CardTitle>
                    {t('portal.settingsDashboard.modules.upload.title')}
                  </CardTitle>
                  <CardBody>
                    <Paragraph>
                      {t('portal.settingsDashboard.modules.upload.description')}
                    </Paragraph>
                  </CardBody>
                </CardContent>
              </Container>
            </Card>
          </Container>
          <Container
            col={4}
            colT={12}
            colM={12}
            pr={16}
            prT={0.1}
            prM={0.1}
            pbT={16}
          >
            <Card
              dataTestId="Dashboard-SmsSettingsTile"
              onClick={gotoSmsSettings}
            >
              <div className={classes.statusContainer}>
                <div
                  className={[
                    classes.status,
                    smsOption ? classes.statusOn : classes.statusOff,
                  ].join(' ')}
                >
                  {getStatusMessage(smsOption)}
                </div>
              </div>
              <Container row mb={32}>
                <CardMedia src="/sms.svg" rounded={false} middle />
                <CardContent center>
                  <CardTitle>
                    {t('portal.settingsDashboard.modules.sms.title')}
                  </CardTitle>
                  <CardBody>
                    <Paragraph>
                      {t('portal.settingsDashboard.modules.sms.description')}
                    </Paragraph>
                  </CardBody>
                </CardContent>
              </Container>
            </Card>
          </Container>
        </Container>
      </Container>
      {user.functionalRole === ROLE_CONTACT_PERSON && (
        <div className={classes.deactivateContainer}>
          <BlokkieTypography variant="ps500">
            {t('portal.settingsDashboard.deactivate')}
          </BlokkieTypography>
          <BlokkieTextLink
            variant="ps700"
            underline
            onClick={() => {
              goToDeactivationPage();
            }}
            color={blokkieTheme.colors.grey.dark}
          >
            {t('portal.settingsDashboard.deactivationLink')}
          </BlokkieTextLink>
        </div>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  settingsPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: blokkieTheme.spacing(8),
  },
  statusContainer: {
    float: 'right',
    width: '100%',
  },
  status: {
    fontSize: '16px',
    float: 'right',
    display: 'inline-block',
    height: '32px',
    minWidth: '72px',
    lineHeight: '32px',
    borderRadius: '40px',
    textAlign: 'center',
  },
  statusOn: {
    backgroundColor: theme.primary01Light,
    color: theme.primary01,
  },
  statusOff: {
    backgroundColor: theme.background,
    color: theme.onBackground,
  },
  deactivateContainer: {
    color: blokkieTheme.colors.grey.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default SettingsDashboard;
