import { fromJS } from 'immutable';
import { get, has } from 'lodash';

import {
  CHECK_BUSINESS_PAYMENT,
  CHECK_BUSINESS_PAYMENT_SUCCESS,
  CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED,
  CHECK_PERSONAL_PAYMENT,
  CHECK_PERSONAL_PAYMENT_SUCCESS,
  CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED,
  CLEAR_GENERIC_ERROR,
  CLEAR_GENERIC_PENDING,
  CLEAR_SESSION,
  CLEAR_TRANSACTION_PROPS,
  CLEAR_UBO_ADDRESS,
  CONFIRM_EMAIL_SUCCESS,
  CREATE_TRANSACTION_SUCCESS,
  GET_CURRENT_ITEM_SUCCESS,
  GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT,
  GET_IDV_STATUS_SUCCESS,
  GET_ISSUERS,
  GET_ISSUERS_SUCCESS,
  GET_POS_DATA,
  GET_POS_DATA_SUCCESS,
  GET_QR_CODE_SUCCESS,
  GET_TERMS,
  GET_TERMS_SUCCESS,
  INITIATE_BUSINESS_PAYMENT,
  INITIATE_BUSINESS_PAYMENT_SUCCESS,
  INITIATE_PERSONAL_PAYMENT,
  INITIATE_PERSONAL_PAYMENT_SUCCESS,
  RESEND_SMS,
  RESEND_SMS_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TOKEN,
  RESET_RESET_PASSWORD,
  SAVE_FIRSTNAME,
  SAVE_PHONE_NUMBER,
  SEARCH_BUSINESS,
  SEARCH_BUSINESS_ERROR,
  SEARCH_BUSINESS_RESET,
  SEARCH_BUSINESS_SUCCESS,
  SECOND_APPLICANT_DETAILS,
  SELECT_BANK,
  SEND_BSN,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SET_AAB_ONBOARDING,
  SET_CDD_ONBOARDING,
  SET_APPLICANT_2_NAME,
  SET_AUTHORIZED_TO_SIGN,
  SET_BAD_SECTOR,
  SET_BIRTH_DATE,
  SET_BUSINESS_PAYMENT_TOKEN,
  SET_CONTACT_NAME,
  SET_COUNTRY,
  SET_EXPAND_ONBOARDING_KYC_CONTAINER,
  SET_GENERIC_ERROR,
  SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING,
  SET_GENERIC_PENDING,
  SET_INITIAL_GET_CURRENT_ITEM,
  SET_IS_SECOND_APPLICANT,
  SET_KEEP_IN_TOUCH,
  SET_KEEP_IN_TOUCH_SUCCESS,
  SET_NICKNAME,
  SET_PASSWORD_SUCCESS,
  SET_PERSONAL_PAYMENT_TOKEN,
  SET_POS_SUPPLIER,
  SET_PRODUCT_TYPE,
  SET_ONBOARDING_FLOW,
  SET_UBO_ADDRESS,
  SET_UBO_BIRTH_DATE,
  SET_UBO_COUNTRY,
  SET_UBO_NAME,
  START_ADD_UBO,
  START_EDIT_UBO,
  START_ORGANISATION_ONBOARDING,
  START_ORGANISATION_ONBOARDING_SUCCESS,
  SUBMIT_PARTIAL_ADDRESS,
  SUBMIT_PARTIAL_ADDRESS_ERROR,
  SUBMIT_PARTIAL_ADDRESS_SUCCESS,
  SUBMIT_PARTIAL_UBO_ADDRESS,
  SUBMIT_POS_DATA,
  SUBMIT_POS_DATA_SUCCESS,
  SUBMIT_UBO_SUCCESS,
  UPDATE_CURRENT_ITEM_NUMBER,
  VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS,
  VERIFY_CODE,
} from './constants';
import { OnboardingFlow } from './onboarding-flow';

const initialState = fromJS({
  config: {
    expandOnboardingKYCContainer: false,
  },
  data: {
    organisationName: '',
    kvkNumber: '',
    email: '',
    password: '',
    legalEntity: 'BV',
    address: {
      houseNumber: '',
      houseNumberAddition: '',
      street: '',
      postalCode: '',
      city: '',
      country: '',
      hidden: false,
    },
    badSector: null,
    contactPersonDetails: {
      firstName: 'Ton',
      lastNamePrefix: '',
      lastName: '',
      authorizedToSign: false,
      jointlyAuthorized: false,
      nickName: '',
      gender: '',
    },
    addressDetails: {
      postalCode: '',
      houseNumber: '',
    },
    birthDate: '',
    phoneNumber: '',
    country: '',
    bsn: '',
    productType: '',
    aabOnboarding: false,
    cddOnboarding: false,
    onboardingFlow: OnboardingFlow.BASIC,
    isProductTypeSet: false,
    hasAbnBank: false,
    bankCode: '',
    overruleOnlyAbnOption: false,
    applicant2: {
      name: {},
      email: '',
    },
    created: '',
    secondApplicantDetails: {},
  },
  filledInAuthorizedToSign: false,
  initialGetCurrentItem: true,
  currentItemNumber: 1,
  totalItems: 10,
  submitPartialAddressError: false,
  possibleAddresses: [],
  personalPayment: {
    token: '',
    idealPaymentUrl: '',
  },
  businessPayment: {
    token: '',
    idealPaymentUrl: '',
  },
  uboDetailsList: [],
  pendingUbo: {
    add: false,
    edit: false,
    birthDate: '',
    address: {
      countryCode: '',
      postalCode: '',
      houseNumber: '',
    },
    involvement: {
      types: [],
      ownerPercentage: '',
    },
  },
  genericError: {},
  genericPending: 0,
  confirmEmailSuccess: false,
  issuers: [],
  issuersPending: false,
  issuersSuccess: false,
  posData: {},
  posDataPending: false,
  posDataSuccess: false,
  posEmail: '',
  posSupplier: '',
  posSystem: '',
  searchResult: {},
  searchBusinessPending: false,
  searchBusinessError: {},
  sendSmsError: {},
  startOnboardingPending: false,
  sendEmailResult: {
    pending: false,
    success: false,
  },
  resendingSms: false,
  isSmsResent: false,
  isUploading: false,
  terms: '',
  isGettingTerms: false,
  initiatingPersonalPayment: false,
  initiatePersonalPaymentSuccess: false,
  checkingPersonalPayment: false,
  personalPaymentStatus: '',
  initiatingBusinessPayment: false,
  initiateBusinessPaymentSuccess: false,
  resetPasswordSuccess: false,
  resetPasswordError: {},
  checkingBusinessPayment: false,
  businessPaymentStatus: '',
  qrCode: '',
  idvStartUrl: '',
  idvStatus: '',
  isSecondApplicant: false,
});

function onboardingKYCContainerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXPAND_ONBOARDING_KYC_CONTAINER:
      return state.setIn(
        ['config', 'expandOnboardingKYCContainer'],
        action.payload.expand,
      );
    case GET_CURRENT_ITEM_SUCCESS:
    case GET_CURRENT_ITEM_SUCCESS_WITHOUT_REDIRECT:
      return state
        .set('initialGetCurrentItem', false)
        .set('currentItemNumber', action.payload.currentItemNumber)
        .set('totalItems', action.payload.totalItems)
        .setIn(
          ['data', 'kvkNumber'],
          get(
            action.payload,
            'kvkDetails.kvkNumber',
            initialState.getIn(['data', 'kvkNumber']),
          ),
        )
        .setIn(
          ['data', 'organisationName'],
          get(
            action.payload,
            'kvkDetails.organisationName',
            initialState.getIn(['data', 'organisationName']),
          ),
        )
        .setIn(
          ['data', 'legalEntity'],
          get(
            action.payload,
            'kvkDetails.legalEntity',
            initialState.getIn(['data', 'legalEntity']),
          ),
        )
        .setIn(
          ['data', 'address'],
          has(action.payload, 'kvkDetails.address')
            ? fromJS(action.payload.kvkDetails.address)
            : fromJS(initialState.getIn(['data', 'address'])),
        )
        .setIn(
          ['data', 'email'],
          get(action.payload, 'email', initialState.getIn(['data', 'email'])),
        )
        .setIn(
          ['data', 'contactPersonDetails'],
          has(action.payload, 'contactPersonDetails')
            ? fromJS(action.payload.contactPersonDetails)
            : fromJS(initialState.getIn(['data', 'contactPersonDetails'])),
        )
        .set(
          'personalPayment',
          has(action.payload, 'personalPayment')
            ? fromJS(action.payload.personalPayment)
            : fromJS(initialState.get('personalPayment')),
        )
        .set(
          'businessPayment',
          has(action.payload, 'businessPayment')
            ? fromJS(action.payload.businessPayment)
            : fromJS(initialState.get('businessPayment')),
        )
        .set(
          'uboDetailsList',
          has(action.payload, 'uboDetailsList')
            ? fromJS(action.payload.uboDetailsList)
            : fromJS(initialState.get('uboDetailsList')),
        )
        .setIn(['data', 'isProductTypeSet'], true)
        .setIn(['data', 'productType'], action.payload.productType)
        .setIn(['data', 'hasAbnBank'], action.payload.hasAbnBank)
        .setIn(['data', 'bankCode'], action.payload.bankCode)
        .setIn(
          ['data', 'created'],
          get(
            action.payload,
            'created',
            initialState.getIn(['data', 'created']),
          ),
        )
        .setIn(
          ['data', 'secondApplicantDetails'],
          has(action.payload, 'secondApplicantDetails')
            ? fromJS(action.payload.secondApplicantDetails)
            : fromJS(initialState.getIn(['data', 'secondApplicantDetails'])),
        );
    case SET_INITIAL_GET_CURRENT_ITEM:
      return state.set('initialGetCurrentItem', action.payload);
    case SET_NICKNAME:
      return state.setIn(
        ['data', 'contactPersonDetails', 'nickName'],
        action.payload,
      );
    case SET_AUTHORIZED_TO_SIGN:
      return state
        .setIn(
          ['data', 'contactPersonDetails', 'authorizedToSign'],
          action.payload.authorizedToSign,
        )
        .setIn(
          ['data', 'contactPersonDetails', 'jointlyAuthorized'],
          action.payload.jointlyAuthorized,
        )
        .set('filledInAuthorizedToSign', true);
    case CONFIRM_EMAIL_SUCCESS:
      return state
        .set('confirmEmailSuccess', true)
        .setIn(['data', 'productType'], action.payload.productType);
    case SEARCH_BUSINESS:
      return state
        .set('searchBusinessPending', true)
        .set('searchBusinessError', fromJS({}));
    case SEARCH_BUSINESS_SUCCESS:
      return state
        .set('searchBusinessPending', false)
        .set('searchResult', fromJS(action.payload))
        .set('searchBusinessError', fromJS({}))
        .setIn(['data', 'organisationName'], action.payload.organisationName);
    case SEARCH_BUSINESS_ERROR:
      return state
        .set('searchBusinessPending', false)
        .set('searchBusinessError', fromJS(action.payload));
    case SEARCH_BUSINESS_RESET:
      return state.set('searchResult', fromJS({}));
    case SELECT_BANK:
      return state.setIn(['data', 'bankCode'], action.payload.bankCode);
    case START_ORGANISATION_ONBOARDING:
      return state.set('startOnboardingPending', true);
    case START_ORGANISATION_ONBOARDING_SUCCESS:
      return state
        .setIn(['data', 'kvkNumber'], action.payload.kvkDetails.kvkNumber)
        .setIn(
          ['data', 'organisationName'],
          action.payload.kvkDetails.organisationName,
        )
        .setIn(['data', 'legalEntity'], action.payload.kvkDetails.legalEntity)
        .setIn(['data', 'address'], fromJS(action.payload.kvkDetails.address))
        .setIn(['data', 'hasAbnBank'], action.payload.hasAbnBank)
        .setIn(['data', 'productType'], action.payload.productType)
        .set('startOnboardingPending', false);
    case SEND_EMAIL:
      return state
        .setIn(['data', 'email'], action.payload)
        .setIn(['sendEmailResult', 'pending'], true)
        .setIn(['sendEmailResult', 'success'], false)
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case SEND_EMAIL_SUCCESS:
      return state
        .setIn(['sendEmailResult', 'pending'], false)
        .setIn(['sendEmailResult', 'success'], true)
        .setIn(['sendEmailResult', 'status'], '');
    case SET_PASSWORD_SUCCESS:
      return state.setIn(['data', 'password'], action.payload.password);
    case SAVE_FIRSTNAME:
      return state.setIn(
        ['data', 'contactPersonDetails', 'firstName'],
        action.payload,
      );
    case SET_BAD_SECTOR:
      return state.setIn(['data', 'badSector'], action.payload);
    case SET_CONTACT_NAME:
      return state
        .setIn(
          ['data', 'contactPersonDetails', 'firstName'],
          action.payload.firstName,
        )
        .setIn(
          ['data', 'contactPersonDetails', 'lastNamePrefix'],
          action.payload.lastNamePrefix,
        )
        .setIn(
          ['data', 'contactPersonDetails', 'lastName'],
          action.payload.lastName,
        )
        .setIn(
          ['data', 'contactPersonDetails', 'gender'],
          action.payload.gender,
        );
    case SET_BIRTH_DATE:
      return state.setIn(['data', 'birthDate'], action.payload);
    case SEND_BSN:
      return state.setIn(['data', 'bsn'], action.payload);
    case SAVE_PHONE_NUMBER:
      return state
        .setIn(['data', 'phoneNumber'], action.payload)
        .set('isSmsResent', false)
        .set('genericError', fromJS({}));
    case RESEND_SMS:
      return state
        .set('resendingSms', true)
        .set('isSmsResent', false)
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case RESEND_SMS_SUCCESS:
      return state.set('resendingSms', false).set('isSmsResent', true);
    case VERIFY_CODE:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case GET_TERMS:
      return state.set('isGettingTerms', true);
    case GET_TERMS_SUCCESS:
      return state
        .set('isGettingTerms', false)
        .set('terms', action.payload.payload.term);
    case SET_COUNTRY:
      return state.setIn(['data', 'country'], action.payload.countryCode);
    case SUBMIT_PARTIAL_ADDRESS:
      return state
        .set('possibleAddresses', fromJS([]))
        .setIn(['data', 'addressDetails'], fromJS(action.payload))
        .set('submitPartialAddressError', false);
    case SUBMIT_PARTIAL_ADDRESS_ERROR:
      return state.set('submitPartialAddressError', true);
    case SUBMIT_PARTIAL_ADDRESS_SUCCESS:
      return state
        .set('possibleAddresses', fromJS(action.payload))
        .set('submitPartialAddressError', false);

    case GET_ISSUERS:
      return state.set('issuersPending', true).set('issuersSuccess', false);
    case GET_ISSUERS_SUCCESS:
      return state
        .set('issuers', fromJS(action.payload))
        .set('issuersPending', false)
        .set('issuersSuccess', true);
    case UPDATE_CURRENT_ITEM_NUMBER:
      return state
        .set('currentItemNumber', action.payload.currentItemNumber)
        .set('totalItems', action.payload.totalItems);
    case INITIATE_PERSONAL_PAYMENT:
      return state.set('initiatingPersonalPayment', true);
    case INITIATE_PERSONAL_PAYMENT_SUCCESS:
      return state
        .set('initiatingPersonalPayment', false)
        .set('initiatePersonalPaymentSuccess', true)
        .setIn(
          ['personalPayment', 'idealPaymentUrl'],
          action.payload.idealPaymentUrl,
        );
    case CHECK_PERSONAL_PAYMENT:
      return state.set('checkingPersonalPayment', true);
    case CHECK_PERSONAL_PAYMENT_SUCCESS_UNFINISHED:
      return state
        .set('checkingPersonalPayment', false)
        .set('personalPaymentStatus', action.payload.paymentStatus);
    case CHECK_PERSONAL_PAYMENT_SUCCESS:
      return state
        .set('checkingPersonalPayment', false)
        .set('personalPaymentStatus', action.payload.paymentStatus);
    case SET_PERSONAL_PAYMENT_TOKEN:
      return state.setIn(['personalPayment', 'token'], action.payload);
    case INITIATE_BUSINESS_PAYMENT:
      return state.set('initiatingBusinessPayment', true);
    case INITIATE_BUSINESS_PAYMENT_SUCCESS:
      return state
        .set('initiatingBusinessPayment', false)
        .set('initiateBusinessPaymentSuccess', true)
        .setIn(
          ['businessPayment', 'idealPaymentUrl'],
          action.payload.idealPaymentUrl,
        );
    case SET_GENERIC_ERROR:
    case SET_GENERIC_ERROR_WITHOUT_GENERIC_PENDING:
      return state
        .set('hasGenericError', true)
        .set('genericError', fromJS(action.payload))
        .set('resendingSms', false)
        .set('startOnboardingPending', false)
        .setIn(['sendEmailResult', 'pending'], false)
        .set('pendingUbo', initialState.get('pendingUbo'));
    case CLEAR_GENERIC_ERROR:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case SET_GENERIC_PENDING:
      return state.set('genericPending', state.get('genericPending') + 1);
    case CLEAR_GENERIC_PENDING:
      return state.set('genericPending', state.get('genericPending') - 1);
    case RESET_PASSWORD_SUCCESS:
      return state
        .set('resetPasswordSuccess', true)
        .set('resetPasswordError', fromJS({}));
    case RESET_PASSWORD_ERROR:
      return state
        .set('resetPasswordSuccess', false)
        .set('resetPasswordError', fromJS(action.error));
    case RESET_PASSWORD_TOKEN:
      return state.set('resetPasswordToken', action.payload.token);
    case RESET_RESET_PASSWORD:
      return state
        .set('resetPasswordSuccess', false)
        .set('resetPasswordError', fromJS({}));
    case CHECK_BUSINESS_PAYMENT:
      return state.set('checkingBusinessPayment', true);
    case CHECK_BUSINESS_PAYMENT_SUCCESS_UNFINISHED:
      return state
        .set('checkingBusinessPayment', false)
        .set('businessPaymentStatus', action.payload.paymentStatus);
    case CHECK_BUSINESS_PAYMENT_SUCCESS:
      return state
        .set('checkingBusinessPayment', false)
        .set('businessPaymentStatus', action.payload.paymentStatus);
    case SET_BUSINESS_PAYMENT_TOKEN:
      return state.setIn(['businessPayment', 'token'], action.payload);
    case SUBMIT_UBO_SUCCESS:
      return state
        .set('uboDetailsList', fromJS(action.payload.uboDetailsList))
        .set('pendingUbo', initialState.get('pendingUbo'));
    case START_ADD_UBO:
      return state
        .set('pendingUbo', initialState.get('pendingUbo'))
        .setIn(['pendingUbo', 'add'], true);
    case START_EDIT_UBO:
      return state
        .set('pendingUbo', fromJS(action.payload))
        .setIn(['pendingUbo', 'edit'], true);
    case SET_UBO_NAME:
      return state
        .setIn(['pendingUbo', 'firstName'], action.payload.firstName)
        .setIn(['pendingUbo', 'lastNamePrefix'], action.payload.lastNamePrefix)
        .setIn(['pendingUbo', 'lastName'], action.payload.lastName);
    case SET_UBO_BIRTH_DATE:
      return state.setIn(['pendingUbo', 'birthDate'], action.payload);
    case SET_UBO_COUNTRY:
      return state.setIn(
        ['pendingUbo', 'address', 'countryCode'],
        action.payload,
      );
    case CLEAR_UBO_ADDRESS:
      return state.setIn(
        ['pendingUbo', 'address'],
        initialState.getIn(['pendingUbo', 'address']),
      );
    case SET_UBO_ADDRESS:
      return state
        .setIn(['pendingUbo', 'address', 'street'], action.payload.street)
        .setIn(
          ['pendingUbo', 'address', 'houseNumber'],
          action.payload.houseNumber,
        )
        .setIn(
          ['pendingUbo', 'address', 'addition'],
          has(action.payload, 'addition') ? action.payload.addition : null,
        )
        .setIn(
          ['pendingUbo', 'address', 'postalCode'],
          action.payload.postalCode,
        )
        .setIn(['pendingUbo', 'address', 'city'], action.payload.city);
    case SUBMIT_PARTIAL_UBO_ADDRESS:
      return state
        .set('possibleAddresses', fromJS([]))
        .setIn(
          ['pendingUbo', 'address', 'postalCode'],
          fromJS(action.payload.postalCode),
        )
        .setIn(
          ['pendingUbo', 'address', 'houseNumber'],
          fromJS(action.payload.houseNumber),
        )
        .set('submitPartialAddressError', false);
    case GET_POS_DATA:
      return state.set('posDataPending', true).set('posDataSuccess', false);
    case GET_POS_DATA_SUCCESS:
      return state
        .set('posData', fromJS(action.payload))
        .set('posDataPending', false)
        .set('posDataSuccess', true);
    case SET_POS_SUPPLIER:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}))
        .set('posSupplier', action.payload);
    case SUBMIT_POS_DATA:
      return state
        .set('posSupplier', action.payload.posSupplier)
        .set('posSystem', action.payload.posSystem);
    case SET_KEEP_IN_TOUCH:
      return state.set('posEmail', action.payload);
    case SUBMIT_POS_DATA_SUCCESS:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case SET_KEEP_IN_TOUCH_SUCCESS:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case SET_AAB_ONBOARDING:
      return state.setIn(['data', 'aabOnboarding'], action.payload);
    case SET_CDD_ONBOARDING:
      return state.setIn(['data', 'cddOnboarding'], action.payload);
    case SET_PRODUCT_TYPE:
      return state
        .setIn(['data', 'productType'], fromJS(action.payload))
        .setIn(['data', 'isProductTypeSet'], true);
    case SET_ONBOARDING_FLOW:
      return state.setIn(['data', 'onboardingFlow'], fromJS(action.payload));
    case CLEAR_SESSION:
      return state
        .set('hasGenericError', false)
        .set('genericError', fromJS({}));
    case GET_QR_CODE_SUCCESS:
      return state.set('qrCode', action.payload.data);
    case CREATE_TRANSACTION_SUCCESS:
      return state.set('idvStartUrl', action.payload.idvStartUrl);
    case GET_IDV_STATUS_SUCCESS:
      return state.set('idvStatus', action.payload.idvStatus);
    case CLEAR_TRANSACTION_PROPS:
      return state
        .set('qrCode', '')
        .set('idvStartUrl', '')
        .set('idvStatus', '');
    case SET_APPLICANT_2_NAME:
      return state.setIn(
        ['data', 'applicant2', 'name'],
        fromJS(action.payload),
      );
    case SECOND_APPLICANT_DETAILS:
      return state
        .setIn(['data', 'applicant2', 'name'], fromJS(action.payload.name))
        .setIn(['data', 'applicant2', 'email'], action.payload.email);

    // Second applicant flow
    case SET_IS_SECOND_APPLICANT:
      return state.set('isSecondApplicant', action.payload);
    case VALIDATE_SECOND_APPLICANT_TOKEN_SUCCESS:
      return state
        .setIn(['data', 'isProductTypeSet'], true)
        .setIn(['data', 'productType'], action.payload.productType)
        .setIn(['data', 'kvkNumber'], action.payload.kvkDetails.kvkNumber)
        .setIn(
          ['data', 'organisationName'],
          action.payload.kvkDetails.organisationName,
        )
        .setIn(
          ['data', 'contactPersonDetails'],
          fromJS(action.payload.contactPersonDetails),
        )
        .setIn(['data', 'legalEntity'], action.payload.kvkDetails.legalEntity)
        .setIn(['data', 'address'], fromJS(action.payload.kvkDetails.address))
        .setIn(['data', 'created'], action.payload.created)
        .setIn(
          ['data', 'secondApplicantDetails', 'firstName'],
          action.payload.secondApplicantFirstName,
        );
    default:
      return state;
  }
}

export default onboardingKYCContainerReducer;

export { initialState };
